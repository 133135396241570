import styled from 'styled-components';
import Input from 'components/Input';
import ButtonIcon from 'components/ButtonIcon';
import { GREY_300, GREY_50 } from '../../../constants';

export const SearchInput = styled(Input)`
    width: 100%;
    input {
        background: ${GREY_50};
        border-color: ${GREY_50};
    }
`;

export const MessagesListContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`;

export const Messages = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 0.5rem;
    overflow-y: auto;
    padding-bottom: 2rem;
`;

export const ListHeader = styled.div`
    display: flex;
    width: 29.75rem;
    height: 3rem;
    padding-left: 0.5rem;
    justify-content: space-between;
    align-items: center;
`;

export const ListTitle = styled.h3`
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.625rem
`;

export const MessageContainer = styled.div`
    display: flex;
    padding: 0.75rem 0.75rem 0.75rem 1rem;
    align-items: center;
    gap: 0.75rem;
    align-self: stretch;
    border-radius: 0.75rem;
    border: 1px solid #D7D7D9;
`;

export const MessageTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    flex: 1 0 0;
    overflow: hidden;
`;

export const MessageTitle = styled.h3`
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
`;

export const MessageContent = styled.p`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    text-overflow: ellipsis;
`;

export const MessageActions = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    color: ${GREY_300};
`;
