import { IconProps } from 'types';

const FilesIcon = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 21 21"
        width={21}
        height={21}
    >
        <title>{title}</title>
        <rect x="7.1665" y="6.66602" width="10" height="10" rx="2" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.8335 6.66732V5.00065C13.8335 4.08018 13.0873 3.33398 12.1668 3.33398H5.50016C4.57969 3.33398 3.8335 4.08018 3.8335 5.00065V11.6673C3.8335 12.5878 4.57969 13.334 5.50016 13.334H7.16683" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default FilesIcon;
