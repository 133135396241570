import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { useCallback, useMemo } from 'react';

const useManageResponderMapping = (agentId: string) => {
    const {
        mutateAsync: createMapping,
        isLoading: isCreatingNewResponderMapping,
    } = useHttpMutation({
        pathname: `/agents/${agentId}/responder-mappings`,
        method: 'POST',
    });

    const {
        mutateAsync: updateMapping,
        isLoading: isUpdatingResponderMapping,
    } = useHttpMutation({
        method: 'PATCH',
    });

    const {
        mutateAsync: deleteMapping,
        isLoading: isDeletingResponderMapping,
    } = useHttpMutation({
        method: 'DELETE',
    });

    const createResponderMapping = useCallback(
        async ({
            feature,
            featureSchema,
            responderId,
        }: {
            feature: string;
            featureSchema: string;
            responderId: string;
        }) => {
            if (!feature || !responderId) return;
            showAppSpinner();
            try {
                await createMapping({
                    body: {
                        feature,
                        featureSchema,
                        responderId,
                    },
                });
                showAppNotification({
                    icon: 'success',
                    title: 'Skill responder created',
                    description: 'Skill responder were created.',
                    timeout: 5000,
                });
            } catch (error) {
                showAppNotification({
                    icon: 'error',
                    title: 'Error',
                    description: 'Something went wrong. Please try again.',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [createMapping],
    );

    const updateResponderMapping = useCallback(
        async ({
            mappingId,
            responderId,
        }: {
            mappingId: string;
            responderId: string;
        }) => {
            if (!mappingId || !responderId) return;
            showAppSpinner();
            try {
                await updateMapping({
                    pathname: `/agents/${agentId}/responder-mappings/${mappingId}`,
                    body: {
                        responderId,
                    },
                });
                showAppNotification({
                    icon: 'success',
                    title: 'Skill updated',
                    description: 'Skill responder were updated.',
                    timeout: 5000,
                });
            } catch (error) {
                showAppNotification({
                    icon: 'error',
                    title: 'Error',
                    description: 'Something went wrong. Please try again.',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [agentId, updateMapping],
    );

    const deleteResponderMapping = useCallback(
        async (mappingId: string) => {
            if (!mappingId) return;
            showAppSpinner();
            try {
                await deleteMapping({
                    pathname: `/agents/${agentId}/responder-mappings/${mappingId}`,
                });
                showAppNotification({
                    icon: 'success',
                    title: 'Skill deleted',
                    description: 'Skill responder were deleted.',
                    timeout: 5000,
                });
            } catch (error) {
                showAppNotification({
                    icon: 'error',
                    title: 'Error',
                    description: 'Something went wrong. Please try again.',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [agentId, deleteMapping],
    );

    return useMemo(
        () => ({
            isLoading: isCreatingNewResponderMapping
                || isUpdatingResponderMapping
                || isDeletingResponderMapping,
            create: createResponderMapping,
            update: updateResponderMapping,
            delete: deleteResponderMapping,
        }),
        [
            isCreatingNewResponderMapping,
            isUpdatingResponderMapping,
            isDeletingResponderMapping,
            createResponderMapping,
            updateResponderMapping,
            deleteResponderMapping,
        ],
    );
};

export default useManageResponderMapping;
