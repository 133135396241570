import styled, { css } from 'styled-components';
import InsightSectionContainer from 'components/InsightSectionContainer';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 1.5rem;
`;

export const StyledInsightSectionContainer = styled(InsightSectionContainer)<{ height?: string }>`    
    flex-direction: column;
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    h2 {
        font-size: 1.625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.25rem;
    }

    ${(props) => props.height && css`
        align-items: start;
        min-height: ${props.height};
    `};

    ${(props) => !props.isLoading && css`
        & > div:last-child {
            display: flex;
            flex: 1;
            align-self: stretch;
            margin-left: -1.5rem;
            margin-right: -1.5rem;
        }
    `};
`;

export const Content = styled.div`
    position: relative;
    display: flex;
    width: 100%;
`;
