import CallInformation from 'components/CallInformation';
import CalendarEventInformation from 'components/CalendarEventInformation';
import CustomerNote from 'components/CustomerNote';
import SMSInformation from 'components/SMSInformation';
import priceFormatter from 'data/services/price/formatter';
import { DisplayContext } from './types';
import { EventContainer } from './styled';

const MessageContent = ({
    type,
    content = {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    context,
}: {
    type?: string;
    content?: Record<string, unknown>,
    context?: DisplayContext;
}) => {
    if (type === 'note') {
        return <CustomerNote {...content} />;
    }

    if (type === 'action') {
        if (content.action === 'inbox-created') return <div>I created this Inbox!</div>;
        if (content.action === 'inbox-read') return <div>I saw this Inbox!</div>;
        if (content.action === 'inbox-solved') return <div>I solved this Inbox ✅!</div>;
        if (content.action === 'inbox-removed') return <div>I removed this Inbox!</div>;
        if (content.action === 'inbox-reopen') return <div>I reopened this Inbox!</div>;
        if (content.action === 'inbox-spam') return <div>I mark this Inbox as spam!</div>;
        if (content.action === 'inbox-not-spam') return <div>I removed this Inbox from spam!</div>;
    }

    if (type === 'call') {
        return <CallInformation sessionId={content.sessionId as string} />;
    }

    if (type === 'calendar-event-created') {
        return (
            <EventContainer>
                <div>
                    I created this new Event!
                </div>
                <CalendarEventInformation
                    agentId={content.agentId as string}
                    eventId={content.eventId as string}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    data={content}
                />
            </EventContainer>
        );
    }

    if (type === 'calendar-event-accepted') {
        return (
            <EventContainer>
                <div>
                    I accepted this Event!
                </div>
                <CalendarEventInformation
                    agentId={content.agentId as string}
                    eventId={content.eventId as string}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    data={content}
                />
            </EventContainer>
        );
    }

    if (type === 'calendar-event-rejected') {
        return (
            <EventContainer>
                <div>
                    I rejected this Event!
                </div>
                <CalendarEventInformation
                    agentId={content.agentId as string}
                    eventId={content.eventId as string}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    data={content}
                />
            </EventContainer>
        );
    }

    if (type === 'calendar-event-removed') {
        return (
            <EventContainer>
                <div>
                    I removed this Event!
                </div>
                <CalendarEventInformation
                    agentId={content.agentId as string}
                    eventId={content.eventId as string}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    data={content}
                />
            </EventContainer>
        );
    }

    if (type === 'calendar-event-rescheduled') {
        return (
            <EventContainer>
                <div>
                    I rescheduled this Event!
                </div>
                <CalendarEventInformation
                    agentId={content.agentId as string}
                    eventId={content.eventId as string}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    data={content}
                />
            </EventContainer>
        );
    }

    if (type === 'calendar-event-sms-sent') {
        return (
            <SMSInformation
                body={content.smsBody as string}
            />
        );
    }

    if (type === 'opportunity-created') {
        return (
            <div>
                I created this Opportunity
                {' '}
                with a Score of
                {' '}
                <strong>
                    {content.score as number || ' (-)'}
                </strong>
                !
            </div>
        );
    }

    if (type === 'opportunity-stage-changed') {
        return (
            <div>
                I changed this Opportunity Stage to
                {' '}
                <strong>
                    {((content.stage as any)?.name) as string}
                </strong>
                !
            </div>
        );
    }

    if (type === 'opportunity-assigned') {
        return (
            <div>
                I assigned
                {' '}
                <strong>
                    {((content.owner as any)?.displayName) as string}
                </strong>
                {' '}
                as the Owner of this Opportunity!
            </div>
        );
    }

    if (type === 'opportunity-unassigned') {
        return (
            <>
                I removed the Owner of this Opportunity!
            </>
        );
    }

    if (type === 'opportunity-removed') {
        return (
            <>
                I removed this Opportunity🗑️!
            </>
        );
    }

    if (type === 'opportunity-qualified') {
        if (content.prevScore === null) {
            return (
                <div>
                    I increased the Opportunity Score to
                    {' '}
                    <strong>
                        {content.score as number}
                    </strong>
                    ! 🔥
                </div>
            );
        }

        return (
            <div>
                I changed the Opportunity Score from
                {' '}
                <strong>
                    {content.prevScore as number}
                </strong>
                {' '}
                to
                {' '}
                <strong>
                    {content.newScore as number}
                </strong>
                !
            </div>
        );
    }

    if (type === 'opportunity-unqualified') {
        return (
            <div>
                I removed the Score from this Opportunity🚫!
            </div>
        );
    }

    if (type === 'opportunity-amount-updated') {
        const prevAmount = priceFormatter('usd').format(((content.prevAmount || 0) as number) / 100);
        const newAmount = priceFormatter('usd').format(((content.newAmount || 0) as number) / 100);

        return (
            <div>
                I changed the Opportunity Ammount from
                {' '}
                <strong>
                    {prevAmount}
                </strong>
                {' '}
                to
                {' '}
                <strong>
                    {newAmount}
                </strong>
                !
            </div>
        );
    }

    if (type === 'message-sent') {
        return (
            <SMSInformation
                body={content.text as string}
                media={content.media as any}
            />
        );
    }

    if (type === 'customer-create') return 'I created this Customer!';
    if (type === 'customer-update') return 'I updated this Customer Information!';
    if (type === 'file') return 'I uploaded a File on this Customer!';

    return null;
};

export default MessageContent;
