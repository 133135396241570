import {
    forwardRef, KeyboardEvent, useCallback, useImperativeHandle, useRef, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import { isEmpty } from '@rainbow-modules/validation';
import AttachFileButton from 'components/AttachFileButton';
// import MicrophoneIcon from 'components/icons/microphone';
import SendIcon from 'components/icons/send';
import FrequentMessage from 'components/FrequentMessage';
import HelpMeWrite from 'components/HelpMeWrite';
import useAttachFiles from 'hooks/useAttachFiles';
import { IMAGE_FORMATS_ACCEPTED_STRING } from '../../constants';
import AttachedFiles from '../AttachedFiles';
import {
    ChatInputContainer, SendButton, ActionsContainer,
    MessageInput, InputContainer,
} from './styled';

interface ChatInputProps {
    onTextSend?: (value: string, attachFiles?: File[]) => void;
    isLoading?: boolean;
    disabled?: boolean;
}

const ChatInput = forwardRef(
    ({
        onTextSend = () => {},
        isLoading = false,
        disabled = false,
    }: ChatInputProps, ref) => {
        const { agentId = '' } = useParams();
        const hiddenFileInputRef = useRef<HTMLInputElement>(null);
        const [value, setValue] = useState('');
        const inputRef = useRef<HTMLTextAreaElement>();
        const {
            files: attachedFiles,
            attach: attachFiles,
            remove: removeAttachedFile,
            clear: clearAttachedFiles,
        } = useAttachFiles({
            onFilesAttached: () => {
                hiddenFileInputRef.current!.value = '';
            },
        });

        const handleKeyDown = useCallback(
            (event: KeyboardEvent<HTMLTextAreaElement>) => {
                if (disabled) return;
                if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault();
                    if (/\S/.test(value)) {
                        onTextSend(value);
                        setValue('');
                    }
                }
            },
            [disabled, onTextSend, value],
        );

        const sendText = useCallback(
            () => {
                if (disabled) return;
                onTextSend(value, attachedFiles);
                setValue('');
                clearAttachedFiles();
            },
            [disabled, onTextSend, value, attachedFiles, clearAttachedFiles],
        );

        useImperativeHandle(ref, () => ({
            focus: () => {
                inputRef.current?.focus();
            },
        }));

        return (
            <ChatInputContainer>
                <InputContainer>
                    <MessageInput
                        variant="shaded"
                        placeholder="Type here..."
                        value={value}
                        grow
                        rows={1}
                        onChange={(event) => setValue(event.target.value)}
                        onKeyDown={handleKeyDown}
                        ref={inputRef}
                        disabled={isLoading || disabled}
                    />
                    <RenderIf isTrue={attachedFiles.length > 0}>
                        <AttachedFiles
                            files={attachedFiles}
                            onRemoveFile={removeAttachedFile}
                        />
                    </RenderIf>
                    <ActionsContainer>
                        <AttachFileButton
                            inputRef={hiddenFileInputRef}
                            disabled={isLoading || disabled}
                            acceptedFormats={IMAGE_FORMATS_ACCEPTED_STRING}
                            onAttachFiles={attachFiles}
                        />
                        {/* <ActionButton
                            borderRadius="semi-square"
                            title="Record Audio"
                            icon={<MicrophoneIcon />}
                            disabled
                        /> */}
                        <FrequentMessage
                            context="messages"
                            onSelectMessage={(message) => {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                // eslint-disable-next-line max-len
                                const textarea = inputRef.current.fieldRef.current.textareaRef.current;
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                                    window.HTMLTextAreaElement.prototype,
                                    'value',
                                ).set;
                                nativeInputValueSetter?.call(textarea, message);
                                const event = new Event('input', { bubbles: true });
                                textarea.dispatchEvent(event);
                                setTimeout(() => inputRef.current?.focus(), 0);
                            }}
                            agentId={agentId}
                        />
                        <RenderIf isTrue={!isEmpty(value)}>
                            <HelpMeWrite
                                value={value}
                                onInsert={(text) => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    // eslint-disable-next-line max-len
                                    const textarea = inputRef.current.fieldRef.current.textareaRef.current;
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                                        window.HTMLTextAreaElement.prototype,
                                        'value',
                                    ).set;
                                    nativeInputValueSetter?.call(textarea, text);
                                    const event = new Event('input', { bubbles: true });
                                    textarea.dispatchEvent(event);
                                    setTimeout(() => inputRef.current?.focus(), 0);
                                }}
                                agentId={agentId}
                            />
                        </RenderIf>
                    </ActionsContainer>
                </InputContainer>
                <SendButton
                    variant="brand"
                    icon={<SendIcon />}
                    onClick={sendText}
                    disabled={!(value.trim() || attachedFiles.length) || disabled}
                />
            </ChatInputContainer>
        );
    },
);

export default ChatInput;
