import { RenderIf } from 'react-rainbow-components';
import { Container, FilesListContainer, Text } from './styled';
import NoteAttachedFile from './file';

interface CustomerNoteProps {
    text?: string;
    attachedFiles?: string[];
}

const CustomerNote = ({ text, attachedFiles = [] }: CustomerNoteProps) => (
    <Container>
        <Text>{text}</Text>
        <RenderIf isTrue={attachedFiles.length > 0}>
            <FilesListContainer>
                {attachedFiles.map((fileId) => <NoteAttachedFile key={fileId} fileId={fileId} />)}
            </FilesListContainer>
        </RenderIf>
    </Container>
);

export default CustomerNote;
