import { RenderIf } from 'react-rainbow-components';
import { useParams } from 'react-router-dom';
import Button from 'components/Button';
import RotateClockwise from 'components/icons/rotateClockwise';
import ArchiveIcon from 'components/icons/archive';
import Check from 'components/icons/check';
import Restore from 'components/icons/restore';
import { useCallback, useState } from 'react';
import useUpdateItem from 'pages/Inbox/hooks/useUpdateItem';
import { confirmModal, showAppNotification } from '@rainbow-modules/app';
import getDisplayName from 'data/services/profile/getDisplayName';
import ResolveModal from 'pages/Inbox/Item/resolveModal';
import ArchiveModal from './archiveModal';

interface TaskActionsProps {
    taskId?: string;
    customer?: Record<string, unknown> | null;
    isLoading?: boolean;
    isArchived?: boolean;
    isSolved?: boolean;
    onResolved?: () => void;
    onArchived?: () => void;
    onRestored?: () => void;
    onReopened?: () => void;
}

const TaskStatusActions = ({
    taskId = '',
    customer,
    isLoading,
    isArchived = false,
    isSolved = false,
    onResolved = () => {},
    onArchived = () => {},
    onRestored = () => {},
    onReopened = () => {},
}: TaskActionsProps) => {
    const { agentId = '' } = useParams();
    const { mutateAsync: updateItem } = useUpdateItem();

    const [isLoadingResolve, setIsLoadingResolve] = useState(false);
    const [isLoadingRestore, setIsLoadingRestore] = useState(false);
    const [isLoadingArchive, setIsLoadingArchive] = useState(false);
    const [isLoadingReopen, setIsLoadingReopen] = useState(false);

    const handleArchive = useCallback(async () => {
        if (!taskId || isArchived) return;

        const result = await confirmModal({
            header: '',
            question: <ArchiveModal />,
            borderRadius: 'semi-square',
            okButtonLabel: 'Confirm',
            cancelButtonLabel: 'Cancel',
        });
        if (!result) return;

        try {
            setIsLoadingArchive(true);
            await updateItem(taskId, 'remove');
            onArchived();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingArchive(false);
        }
    }, [isArchived, onArchived, taskId, updateItem]);

    const handleReopen = useCallback(async () => {
        if (!taskId || !customer) return;
        const name = getDisplayName(customer);
        setIsLoadingReopen(true);
        try {
            await updateItem(taskId, 'reopen');
            onReopened();
            showAppNotification({
                icon: 'success',
                title: 'Task Reopened!',
                description: `${name}'s issue has been successfully reopened.`,
                timeout: 5000,
            });
        } catch (error) {
            console.error(error);
            showAppNotification({
                icon: 'error',
                title: 'Error',
                description: 'Something went wrong. Please try again.',
                timeout: 5000,
            });
        }
        setIsLoadingReopen(false);
    }, [customer, onReopened, taskId, updateItem]);

    const handleSolve = useCallback(async () => {
        if (!taskId || !customer) return;
        const name = getDisplayName(customer);
        let note = '';
        const result = await confirmModal({
            header: '',
            // eslint-disable-next-line max-len
            question: <ResolveModal agentId={agentId} onNoteChanged={(value) => { note = value; }} />,
            borderRadius: 'semi-square',
            okButtonLabel: 'Confirm',
            cancelButtonLabel: 'Cancel',
        });
        if (!result) return;

        setIsLoadingResolve(true);
        try {
            await updateItem(taskId, 'solve', note);
            onResolved();
            showAppNotification({
                icon: 'success',
                title: 'Task Solved!',
                description: `${name}'s issue has been successfully marked as Solved.`,
                timeout: 5000,
            });
        } catch (error) {
            // TODO: handle error
            console.error(error);
            showAppNotification({
                icon: 'error',
                title: 'Error',
                description: 'Something went wrong. Please try again.',
                timeout: 5000,
            });
        }
        setIsLoadingResolve(false);
    }, [customer, onResolved, taskId, updateItem, agentId]);

    const handleRestore = useCallback(async () => {
        if (!taskId || !isArchived) return;
        setIsLoadingRestore(true);
        try {
            await updateItem(taskId, 'restore');
            onRestored();
            showAppNotification({
                icon: 'success',
                title: 'Task Restored!',
                description: 'Task has been successfully resstored.',
                timeout: 5000,
            });
        } catch (error) {
            console.error(error);
            showAppNotification({
                icon: 'error',
                title: 'Error',
                description: 'Something went wrong. Please try again.',
                timeout: 5000,
            });
        }
        setIsLoadingRestore(false);
    }, [isArchived, onRestored, taskId, updateItem]);

    const isOverallLoading = isLoading
    || isLoadingResolve
    || isLoadingArchive
    || isLoadingReopen
    || isLoadingRestore;

    return (
        <>
            <RenderIf isTrue={!isArchived}>
                <Button
                    borderRadius="semi-rounded"
                    variant="base"
                    disabled={isOverallLoading}
                    onClick={handleArchive}
                >
                    <ArchiveIcon className="rainbow-m-right_x-small" />
                    Archive
                </Button>
            </RenderIf>
            <RenderIf isTrue={isArchived}>
                <Button
                    borderRadius="semi-rounded"
                    disabled={isOverallLoading}
                    variant="base"
                    onClick={handleRestore}
                >
                    <Restore className="rainbow-m-right_x-small" />
                    Restore
                </Button>
            </RenderIf>
            <RenderIf isTrue={isSolved && !isArchived}>
                <Button
                    borderRadius="semi-rounded"
                    variant="base"
                    disabled={isOverallLoading}
                    onClick={handleReopen}
                >
                    <RotateClockwise className="rainbow-m-right_x-small" />
                    Reopen
                </Button>
            </RenderIf>
            <RenderIf isTrue={!isSolved && !isArchived}>
                <Button
                    borderRadius="semi-rounded"
                    variant="brand"
                    disabled={isOverallLoading}
                    onClick={handleSolve}
                >
                    <Check className="rainbow-m-right_x-small" />
                    Resolve
                </Button>
            </RenderIf>
        </>
    );
};

export default TaskStatusActions;
