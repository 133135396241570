import { RefObject } from 'react';
import ImageIcon from 'components/icons/image';
import { ActionButton, StyledFileInput } from './styled';

interface Props {
    inputRef?: RefObject<HTMLInputElement>,
    acceptedFormats?: string;
    disabled?: boolean;
    onAttachFiles?: (filesList: FileList | null) => void,
}

const AttachFileButton = ({
    inputRef,
    acceptedFormats,
    disabled = false,
    onAttachFiles = () => {},
}: Props) => (
    <>
        <ActionButton
            tooltip="Attach image"
            icon={<ImageIcon />}
            onClick={() => inputRef?.current?.click()}
            disabled={disabled}
        />
        <StyledFileInput
            type="file"
            multiple
            ref={inputRef}
            onChange={(event) => onAttachFiles(event.target.files)}
            accept={acceptedFormats}
        />
    </>
);

export default AttachFileButton;
