import {
    useState, useRef, useEffect, useMemo,
} from 'react';
import { isEmpty } from '@rainbow-modules/validation';
import { UniversalFormModalProps } from '@rainbow-modules/forms/lib/components/UniversalFormModal';
import Clipboard from 'components/icons/clipboard';
import {
    useConnectModal, useOpenModal, useOutsideClick, useWindowResize,
} from '@rainbow-modules/hooks';
import { InternalOverlay, RenderIf } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import Plus from 'components/icons/plus';
import Setting from 'components/icons/setting';
import SearchIcon from 'components/icons/search';
import useManageFrequentMessages from 'data/hooks/useManageFrequentMessages';
import ChevronUp from 'components/icons/chevronUp';
import ChevronDown from 'components/icons/chevronDown';
import useFrequentMessages from 'data/firestore/agent/frequentmessage/useCollection';
import { query, where } from 'firebase/firestore';
import {
    ActionButton,
    Dropdown,
    DropdownHeader,
    DropdownTitle,
    SearchInput,
    DropdownContent,
    StyledUl,
    TopGradient,
    BottomGradient,
    ModalStyle,
    StyledDrawer,
    ListContainer,
    StyledEmptyMessage,
} from './styled';
import FrequentMessagesList from './MessagesList';
import { ADD_EDIT_FREQUENT_MESSAGE, FREQUENT_MESSAGE_SETTINGS } from '../../constants';
import { MessageFormMode } from './types';
import CreateEditMessageForm from './CreateEditForm';
import Messages from './messages';
import useArrowScrolling from './hooks/useArrowScrolling';

interface Props {
    context?: 'notes' | 'messages';
    onSelectMessage: (message: string) => void;
    agentId: string;
}

const FrequentMessage = ({ context = 'notes', onSelectMessage, agentId }: Props) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const triggerRef = useRef<HTMLButtonElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const { data } = useFrequentMessages(agentId as string, {
        listQueryFn: (collectionRef) => query(collectionRef, where('context', '==', context)),
    });

    const isEmptyList = data.length === 0;
    const handleToggleOverlay = () => {
        setOverlayVisible(!isOverlayVisible);
    };

    const connectedAddModifyProps = useConnectModal(
        ADD_EDIT_FREQUENT_MESSAGE,
        { size: 'small', slideFrom: 'right' },
    );

    const [, closeCreateEditDrawer] = useOpenModal(ADD_EDIT_FREQUENT_MESSAGE);
    const {
        mode,
        initialValues,
        onSubmit,
        isOpen: isOpenAddEditDrawer,
        ...addEditDrawerProps
    } = connectedAddModifyProps;

    const {
        isOpen: isOpenSettingsDrawer,
        ...connectedSettingsProps
    } = useConnectModal(
        FREQUENT_MESSAGE_SETTINGS,
        { size: 'small', slideFrom: 'right' },
    );

    const [openSettingsDrawer, closeSettingsDrawer] = useOpenModal(FREQUENT_MESSAGE_SETTINGS);

    const { create: createFrequentMessage } = useManageFrequentMessages({
        agentId,
        context,
        onFrequentMessageCreated: closeCreateEditDrawer,
    });

    const {
        showScrollUpArrow,
        showScrollDownArrow,
        setRef: menuRef,
        scrollUp,
        scrollDown,
        stopScolling,
        updateScrollingArrows,
    } = useArrowScrolling<HTMLUListElement>(isOverlayVisible);

    useOutsideClick(
        dropdownRef,
        (event) => {
            // eslint-disable-next-line max-len
            if (event.target !== triggerRef.current) {
                setOverlayVisible(false);
            }
        },
        isOverlayVisible && !(isOpenAddEditDrawer || isOpenSettingsDrawer),
    );
    useWindowResize(
        () => setOverlayVisible(false),
        isOverlayVisible && !(isOpenAddEditDrawer || isOpenSettingsDrawer),
    );

    /* Context Texts Logic */
    const tooltipText = context === 'messages' ? 'Use Frequent Message' : 'Use Frequent Note';
    const addTooltipButton = context === 'messages' ? 'Create Frequent Message' : 'Create Frequent Note';
    const settingsTooltipButton = context === 'messages' ? 'Manage Frequent Messages' : 'Manage Frequent Notes';
    const titleText = context === 'messages' ? 'Frequent Messages' : 'Frequent Note';
    const emptyMessageTitle = context === 'messages' ? 'No Frequent Messages Yet' : 'No Frequent Notes Yet';
    const emptyMessageDescription = context === 'messages' ? 'Save frequently used messages here to quickly insert them when chatting.' : 'Save your commonly written notes here for quick access when needed.';
    const emptyMessageEmoji = context === 'messages' ? '📝' : '📓';
    const searchPlaceholderText = context === 'messages' ? 'Find frequent messages...' : 'Find frequent notes...';

    const handleSelectMessage = (description: string) => {
        onSelectMessage(description);
        setOverlayVisible(false);
    };

    const filteredData = useMemo(
        () => {
            const regex = new RegExp(searchQuery, 'gi');
            return (
                isEmpty(searchQuery)
                    ? data
                    : data.filter(
                        (message) => regex.test(message.title) || regex.test(message.content),
                    )
            );
        },
        [data, searchQuery],
    );

    useEffect(() => {
        if (isOverlayVisible) {
            setTimeout(() => {
                searchInputRef.current?.focus();
            }, 0);
        }
    }, [isOverlayVisible]);

    return (
        <>
            <ModalStyle
                isSettingsModalOpen={isOpenSettingsDrawer}
                isCreateModalOpen={isOpenAddEditDrawer}
            />
            <ActionButton
                tooltip={tooltipText}
                icon={<Clipboard />}
                onClick={handleToggleOverlay}
                ref={triggerRef}
            />
            <InternalOverlay
                isVisible={isOverlayVisible}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                triggerElementRef={() => triggerRef?.current?.buttonRef}
            >
                <Dropdown ref={dropdownRef}>
                    <DropdownHeader>
                        <DropdownTitle>
                            {titleText}
                        </DropdownTitle>
                        <ButtonIcon
                            icon={<Plus />}
                            tooltip={addTooltipButton}
                            onClick={() => createFrequentMessage()}
                        />
                        <ButtonIcon
                            icon={<Setting />}
                            tooltip={settingsTooltipButton}
                            onClick={() => openSettingsDrawer({})}
                        />
                    </DropdownHeader>
                    <RenderIf isTrue={!isEmptyList}>
                        <DropdownContent>
                            <SearchInput
                                icon={<SearchIcon />}
                                placeholder={searchPlaceholderText}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                ref={searchInputRef}
                            />
                            <ListContainer>
                                <TopGradient
                                    onMouseEnter={scrollUp}
                                    onMouseLeave={stopScolling}
                                >
                                    <RenderIf isTrue={showScrollUpArrow}>
                                        <ChevronUp />
                                    </RenderIf>
                                </TopGradient>
                                <StyledUl
                                    ref={menuRef}
                                    role="presentation"
                                    onScroll={updateScrollingArrows}
                                >
                                    <Messages
                                        data={filteredData}
                                        onMessageClick={handleSelectMessage}
                                    />
                                </StyledUl>
                                <BottomGradient
                                    onMouseEnter={scrollDown}
                                    onMouseLeave={stopScolling}
                                >
                                    <RenderIf
                                        isTrue={showScrollDownArrow && filteredData.length > 5}
                                    >
                                        <ChevronDown />
                                    </RenderIf>
                                </BottomGradient>
                            </ListContainer>
                        </DropdownContent>
                    </RenderIf>
                    <RenderIf isTrue={isEmptyList}>
                        <StyledEmptyMessage
                            message={emptyMessageDescription}
                            emoji={emptyMessageEmoji}
                            title={emptyMessageTitle}
                            size="small"
                        />
                    </RenderIf>
                </Dropdown>
            </InternalOverlay>
            <StyledDrawer
                {...addEditDrawerProps}
                isOpen={isOpenAddEditDrawer}
                width={520}
                hideCloseButton
                id="frequent-message-create-edit-drawer"
                onRequestClose={closeCreateEditDrawer}
            >
                <CreateEditMessageForm
                    mode={mode as MessageFormMode}
                    context={context}
                    onRequestClose={closeCreateEditDrawer}
                    initialValues={initialValues as Record<string, unknown>}
                    onSubmit={onSubmit as UniversalFormModalProps['onSubmit']}
                />
            </StyledDrawer>
            <StyledDrawer
                {...connectedSettingsProps}
                isOpen={isOpenSettingsDrawer}
                width={540}
                hideCloseButton
                id="frequent-message-settings-drawer"
                onRequestClose={closeSettingsDrawer}
            >
                <FrequentMessagesList
                    agentId={agentId}
                    onRequestClose={closeSettingsDrawer}
                    context={context}
                />
            </StyledDrawer>
        </>
    );
};

export default FrequentMessage;
