import TextItem from '../TextItem';
import { Container, Title } from './styled';

interface TextTransformationsListProps {
    title?: string;
    text?: string;
    isLoading?: boolean;
    disableUndo?: boolean;
    onInsert?: (value: string) => void;
    onUndo?: () => void;
    onRegenerate?: () => void;
}

const TextTransformationsList = ({
    title = '',
    text = '',
    isLoading = false,
    disableUndo = false,
    onInsert = () => {},
    onUndo = () => {},
    onRegenerate = () => {},
}: TextTransformationsListProps) => (
    <Container>
        <Title>Results:</Title>
        <TextItem
            title={title}
            text={text}
            onInsert={onInsert}
            onUndo={onUndo}
            onRegenerate={onRegenerate}
            disableUndo={disableUndo}
            isLoading={isLoading}
        />
    </Container>
);

export default TextTransformationsList;
