import { RenderIf } from 'react-rainbow-components';
import LoadingShape from 'components/LoadingShape';
import ButtonIcon from 'components/ButtonIcon';
import Refresh from 'components/icons/refresh';
import Undo from 'components/icons/undo';
import {
    ItemContainer,
    ActionsContainer,
    ItemContent,
    Title,
    Text,
    StyledButton,
    StyledCopyToClipboardButton,
} from './styled';

interface TextItemProps {
    isLoading?: boolean;
    title?: string;
    text: string;
    hideActions?: boolean;
    disableUndo?: boolean;
    onInsert?: (value: string) => void;
    onUndo?: () => void;
    onRegenerate?: () => void;
}

const TextItem = ({
    title: titleProp,
    text: textProp,
    hideActions = false,
    isLoading = false,
    disableUndo = false,
    onInsert = () => {},
    onUndo = () => {},
    onRegenerate = () => {},
}: TextItemProps) => {
    const title = isLoading ? <LoadingShape width="40px" /> : titleProp;
    const text = isLoading ? <LoadingShape width="80%" /> : textProp;
    return (
        <ItemContainer>
            <ItemContent>
                <Title>{title}</Title>
                <Text>{text}</Text>
            </ItemContent>
            <RenderIf isTrue={!hideActions}>
                <ActionsContainer>
                    <div>
                        <StyledButton
                            borderRadius="semi-rounded"
                            variant="brand"
                            size="small"
                            className="rainbow-m-right_x-small"
                            label="Insert"
                            disabled={isLoading}
                            onClick={() => onInsert(textProp)}
                        />
                        <StyledCopyToClipboardButton
                            label="Copy"
                            value={textProp}
                            variant="base"
                            disabled={isLoading}
                        />
                    </div>
                    <div>
                        <ButtonIcon
                            icon={<Undo />}
                            variant="base"
                            tooltip="Undo"
                            disabled={isLoading || disableUndo}
                            onClick={onUndo}
                            className="rainbow-m-right_x-small"
                        />
                        <ButtonIcon
                            icon={<Refresh />}
                            variant="base"
                            tooltip="Try Again"
                            disabled={isLoading}
                            onClick={onRegenerate}
                        />
                    </div>
                </ActionsContainer>
            </RenderIf>
        </ItemContainer>
    );
};

export default TextItem;
