import styled from 'styled-components';
import { UniversalForm } from '@rainbow-modules/forms';
import { Textarea } from 'react-rainbow-components';
import Input from 'components/Input';
import { GREY_400, DIVIDER } from '../../../constants';

export const StyledUniversalForm = styled(UniversalForm)`
    height: 100%`;

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: stretch;
    gap: 1rem;
    padding: 1rem 0 1rem;
    flex: 1;
`;

export const Row = styled.div`
    display: flex;
    flex: 0;
    align-items: stretch;
    gap: 1rem;
`;
export const FormActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1rem;
    align-self: stretch;
`;

export const StyledInput = styled(Input)`
    flex: 1;
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    input {
        border: 1px solid ${DIVIDER};

        ::placeholder {
            font-weight: 300 !important;
        }
    }

    label + div > span {
        color: ${GREY_400};
        width: 1.5rem;
        flex-shrink: 0;
        
        svg {
            width: 1.5rem !important;
            height: 1.5rem !important;
            font-size: 1.5rem !important;
        }        
    },

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;

export const StyledTextarea = styled(Textarea)`
    display: flex;
    box-sizing: border-box;
    flex: 1;

    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    > div {
        border: none;
        box-shadow: none;
        box-sizing: border-box;
    }

    textarea {
        width: 100%;
        box-sizing: border-box;
        border: 1px solid ${DIVIDER};
        padding: 0.5rem 0.875rem;

        :focus, :active {
            width: 100%;
            padding: 0.5rem 0.875rem;
            border: 1px solid ${DIVIDER};
        }

        ::placeholder {
            font-weight: 300 !important;
        }
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;
