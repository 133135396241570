import { Conversation } from '@twilio/conversations';
import { useQuery } from 'react-query';
import useTwilioQueryKey from './useTwilioQueryKey';

const useMessagesCount = (
    customerId: string,
    conversation?: Conversation,
) => {
    const key = useTwilioQueryKey(['messages-count', customerId], conversation);
    return useQuery<unknown, unknown, number>(
        key,
        () => conversation?.getMessagesCount(),
        {
            enabled: Boolean(conversation),
            cacheTime: 0,
        },
    );
};

export default useMessagesCount;
