import styled from 'styled-components';
import Button from 'components/Button';
import CopyToClipboardButton from 'components/CopyToClipboardButton';

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 1.25rem;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 0.75rem;
    background: #F5F5F6;
`;

export const ActionsContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    align-self: stretch;
`;

export const ItemContent = styled.div`
    display: flex;
    flex: 1;
    align-self: stretch;
    flex-direction: column;
    gap: 0.5rem;
`;

export const Title = styled.div`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem;
`;

export const Text = styled.div`
    width: 100%;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
`;

export const StyledButton = styled(Button)`
    height: 2.5rem;
    line-height: unset;
    padding: 0.5rem 1.25rem;
`;

export const StyledCopyToClipboardButton = styled(CopyToClipboardButton)`
    height: 2.5rem;
    line-height: unset;
    padding: 0.5rem 1.25rem;

    & > svg {
        width: 1.25rem !important;
        height: 1.25rem !important;
    }
`;
