import EmptyMessage from 'components/EmptyMessage';
import { Memory } from 'data/firestore/agent/customer/memory/types';
import { EntityGet } from 'data/firestore/types';
import { StyledVirtuoso } from './styled';
import ItemMemory from './itemMemory';

const virtuosoComponents = {
    EmptyPlaceholder: () => (
        <EmptyMessage message="No memory for this customer have been created yet." />
    ),
};

const VirtuosoItemContent = (_index: number, memory: EntityGet<Memory>) => {
    const { id } = memory;
    return (
        <ItemMemory
            key={`customer-memory__${_index}__${id}`}
            memory={memory}
        />
    );
};

const MemoriesList = ({ data = [] }: { data?: EntityGet<Memory>[] }) => (
    <StyledVirtuoso
        data={data}
        totalCount={data.length}
        initialTopMostItemIndex={0}
        components={virtuosoComponents as any}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        itemContent={VirtuosoItemContent}
    />
);

export default MemoriesList;
