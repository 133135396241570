import {
    forwardRef, KeyboardEvent, useCallback, useImperativeHandle, useMemo, useRef, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import AttachFileButton from 'components/AttachFileButton';
import AttachedFiles from 'components/AttachedFiles';
// import MicrophoneIcon from 'components/icons/microphone';
import SendIcon from 'components/icons/send';
import FrequentMessage from 'components/FrequentMessage';
import useAttachFiles from 'hooks/useAttachFiles';
import { IMAGE_FORMATS_ACCEPTED_STRING } from '../../constants';
import {
    Container, SendButton, ActionsContainer,
    MessageInput, InputContainer,
} from './styled';

interface ChatInputProps {
    className?: string;
    onSend?: (note: string, files: File[]) => Promise<void>;
    disableSend?: boolean;
}

const ChatInput = forwardRef(
    ({
        className,
        onSend = async () => {},
        disableSend = false,
    }: ChatInputProps, ref) => {
        const [value, setValue] = useState('');
        const inputRef = useRef<HTMLTextAreaElement>();
        const hiddenFileInputRef = useRef<HTMLInputElement>(null);
        const normCleanValue = useMemo(() => (value || '').trim(), [value]);
        const { agentId = '' } = useParams();
        const {
            files: attachedFiles,
            attach: attachFiles,
            remove: removeAttachedFile,
            clear: clearAttachedFiles,
        } = useAttachFiles({
            onFilesAttached: () => {
                hiddenFileInputRef.current!.value = '';
            },
        });

        const sendText = useCallback(
            async () => {
                try {
                    await onSend(normCleanValue, attachedFiles);
                    setValue('');
                    clearAttachedFiles();
                } catch (error) {
                    // no catch
                }
            },
            [onSend, clearAttachedFiles, attachedFiles, normCleanValue],
        );

        const handleKeyDown = useCallback(
            (event: KeyboardEvent<HTMLTextAreaElement>) => {
                if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault();
                    if (/\S/.test(normCleanValue)) {
                        sendText();
                    }
                }
            },
            [normCleanValue, sendText],
        );

        useImperativeHandle(ref, () => ({
            focus: () => {
                inputRef.current?.focus();
            },
        }));

        const setInputValue = (text: string) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line max-len
            const textarea = inputRef.current.fieldRef.current.textareaRef.current;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLTextAreaElement.prototype,
                'value',
            ).set;
            nativeInputValueSetter?.call(textarea, text);
            const event = new Event('input', { bubbles: true });
            textarea.dispatchEvent(event);
            setTimeout(() => inputRef.current?.focus(), 0);
        };

        return (
            <Container className={className}>
                <InputContainer>
                    <MessageInput
                        variant="shaded"
                        placeholder="Write a note here..."
                        value={value}
                        grow
                        rows={1}
                        onChange={(event) => setValue(event.target.value)}
                        onKeyDown={handleKeyDown}
                        ref={inputRef}
                        disabled={disableSend}
                    />
                    <RenderIf isTrue={attachedFiles.length > 0}>
                        <AttachedFiles
                            files={attachedFiles}
                            onRemoveFile={removeAttachedFile}
                        />
                    </RenderIf>
                    <ActionsContainer>
                        <AttachFileButton
                            inputRef={hiddenFileInputRef}
                            acceptedFormats={IMAGE_FORMATS_ACCEPTED_STRING}
                            onAttachFiles={attachFiles}
                        />
                        {/* <ActionButton
                            borderRadius="semi-square"
                            title="Record Audio"
                            icon={<MicrophoneIcon />}
                            disabled
                        /> */}
                        <FrequentMessage
                            context="notes"
                            onSelectMessage={setInputValue}
                            agentId={agentId}
                        />
                    </ActionsContainer>
                </InputContainer>
                <SendButton
                    variant="brand"
                    icon={<SendIcon />}
                    onClick={sendText}
                    disabled={disableSend || !normCleanValue}
                />
            </Container>
        );
    },
);

export default ChatInput;
