import styled from 'styled-components';
import ButtonIcon from 'components/ButtonIcon';
import { Textarea } from 'react-rainbow-components';
import Diamonds from 'components/icons/diamonds';
import { GREY_200, WHITE } from '../../../constants';

export const InputContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    align-self: stretch;
    padding-left: 2.25rem;
    border-radius: 0.75rem;
    border: 1px solid #DBDADE;
    color: #88888D;
`;

export const StyledTextarea = styled(Textarea)`
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0;
    background-clip: unset;
    
    &::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
    & > div {
        border: none;
        border-radius: none;
        box-shadow: none;
        background: ${WHITE};
    }
    textarea,
    textarea[disabled] {
        max-height: 200;
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
        padding: 0;
        border-radius: 0;
        padding: 0.75rem 0.875rem;

        &:focus,
        &:active {
            border-radius: 0;
            padding: 0.75rem 0.875rem;
        }

        ::placeholder {
            color: ${GREY_200} !important;
            font-weight: 200;
        }
    }
`;

export const ArrowButton = styled(ButtonIcon)`
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    right: 4px;
    top: 4px;
`;

export const StyledDiamondsIcon = styled(Diamonds)`
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    left: 0.75rem;
    top: 0.75rem;
`;
