import { useConnectModal } from '@rainbow-modules/hooks';
import FormModal from 'components/FormModal';
import { ADD_EDIT_OPPORTUNITY_PIPELINE_STAGE_MODAL } from '../../constants';
import Fields from './fields';

const mapFormValues = (values: Record<string, unknown>) => {
    const {
        ...rest
    } = values;

    return {
        ...rest,
    };
};

const CreateEditOpportunityStageForm = () => {
    const {
        onSubmit, ...connectedModalProps
    } = useConnectModal(ADD_EDIT_OPPORTUNITY_PIPELINE_STAGE_MODAL);

    const onSubmitMiddleware = (formValues: Record<string, unknown>) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (onSubmit) onSubmit(mapFormValues(formValues));
    };

    return (
        <FormModal
            {...connectedModalProps}
            borderRadius="semi-square"
            size="small"
            fields={Fields}
            onSubmit={onSubmitMiddleware}
        />
    );
};

export default CreateEditOpportunityStageForm;
