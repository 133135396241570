import Send from 'components/icons/send';
import {
    StyledLi,
    IconContainer,
    StyledOption,
    LeftContent,
    OptionName,
    OptionDescription,
} from './styled';

interface Props {
    name: string;
    description: string;
    onClick: (description: string) => void;
}

const Option = ({ name, description, onClick }: Props) => (
    <StyledLi onClick={() => onClick(description)}>
        <StyledOption role="option">
            <LeftContent>
                <OptionName>
                    {name}
                </OptionName>
                <OptionDescription>
                    {description}
                </OptionDescription>
            </LeftContent>
            <IconContainer>
                <Send />
            </IconContainer>
        </StyledOption>
    </StyledLi>
);

export default Option;
