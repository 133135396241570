import { Field } from 'react-final-form';
import { validateNotEmpty } from './validators';
import {
    FieldsContainer,
    StyledInput,
    Row,
    StyledTextarea,
} from './styled';
import { MessageContext } from '../types';

const placeholders: Record<string, Record<MessageContext, string>> = {
    title: {
        messages: 'Enter a friendly name for the frequent message',
        notes: 'Enter a friendly name for the frequent note',
    },
    content: {
        messages: 'Enter the content for the frequent message',
        notes: 'Enter the content for the frequent note',
    },
};

const Fields = ({ context }: { context: MessageContext }) => (
    <FieldsContainer>
        <Row>
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledInput}
                name="title"
                label="Friendly Name"
                labelAlignment="left"
                placeholder={placeholders.title[context]}
                borderRadius="semi-rounded"
                autoComplete="off"
                required
                validate={validateNotEmpty('This field is required')}
            />
        </Row>
        <Row>
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledTextarea}
                name="content"
                label="Content"
                labelAlignment="left"
                placeholder={placeholders.content[context]}
                borderRadius="semi-rounded"
                rows={8}
                required
                validate={validateNotEmpty('This field is required')}
            />
        </Row>
    </FieldsContainer>
);

export default Fields;
