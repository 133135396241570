import styled from 'styled-components';
import { Textarea } from 'react-rainbow-components';
import MessageLoadingShape from 'components/MessageLoadingShape';
import { color } from 'theme';
import ButtonIcon from 'components/ButtonIcon';
import { DIVIDER, WHITE, GREY_200 } from '../../constants';

export const ContentPage = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: calc(100vh - 120px);
`;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${WHITE};
    border-radius: 1.5rem;
    box-shadow: 0px 4px 18px 0px rgba(75, 70, 92, 0.10);
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    height: calc(100vh - 161px);
`;

export const Header = styled.header`
    display: flex;
    flex-direction: column;
`;

export const PrimaryBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    padding: 0.25rem 0.85rem 0 0.85rem;
`;

export const SecondaryBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3.625rem;
    padding: 0.125rem 0.85rem 0 1.25rem;
    width: 100%;
`;

export const Divider = styled.hr`
    flex-shrink: 0;
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: ${DIVIDER};
    margin: 0px;
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
    flex-grow: 1;
    height: fit-content;
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0.5rem 0;
`;

export const ModalIconContainer = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 1.5rem;
    background-color: ${color('brand.light')};
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
        width: 2.5rem;
        height: 2.5rem;
        color: ${color('brand.main')};
    }
`;

export const ModalTitle = styled.h1`
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 400;
    text-align: center;
`;
export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const ModalDescription = styled.p`
    font-size: 0.8rem;
    text-align: center;
    color: ${color('text.header')};
`;

export const TitleLoadingLoadingShape = styled(MessageLoadingShape)`
    align-items: center;
    gap: 12px; 
    padding: 0;
`;

export const BoxContainer = styled.div`
    display: flex;
    padding: 1rem 0.5rem 0.5rem 1rem;
    justify-content: stretch;
    align-items: flex-end;
    gap: 0.5rem;
    border-radius: 0.5rem 0.75rem 2rem 0.75rem;
    border: 1px solid ${DIVIDER};
    margin: 1rem 0 0;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    flex: 1 0 0;
`;

export const NoteInput = styled(Textarea)`
    width: 100%;
    max-height: 460px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0;
    background-clip: unset;
    
    &::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
    & > div {
        border none;
        border-radius: none;
        box-shadow: none;
        background: ${WHITE};
    }
    textarea {
        max-height: 420px;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
        padding: 0;
        border-radius: 0;

        &:focus,
        &:active {
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5rem;
            border-radius: 0;
            padding: 0;
        }

        &[disabled] {
            background: ${WHITE};
            color: ${GREY_200};
            padding-left: 0;
            padding-right: 0;

            &:focus,
            &:active {
                padding: 0.625rem 0;
            }
        }
    }
`;

export const ActionButton = styled(ButtonIcon)`
    width: 3rem;
    height: 3rem;
    svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
        font-size: 1.5rem !important;
    }
`;

export const ActionsContainer = styled.div`
    display: flex;
    padding: 0.25rem 0rem;
    align-items: flex-start;
    align-self: stretch;
    border-top: 1px solid ${DIVIDER};
`;
