import styled, { css } from 'styled-components';
import { GREY_800 } from '../../constants';

export const EmptyMessageContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    gap: 0.5rem;
`;

export const EmptyMessageDescription = styled.p`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: ${GREY_800};
    text-align: center;
`;

export const EmptyMessageEmoji = styled.h2<{ size?: 'small' | 'medium' }>`
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 150%;

    ${(props) => props.size === 'small' && css`
        font-size: 3rem;
    `}
`;

export const EmptyMessageTitle = styled.h1<{ size?: 'small' | 'medium' }>`
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    color: ${GREY_800};

    ${(props) => props.size === 'small' && css`
        font-size: 1rem;
        font-weight: 700;
    `}
`;
