import styled, { css } from 'styled-components';
import { Drawer } from 'react-rainbow-components';
import { GREY_50 } from '../../../../constants';

export const StyledDrawer = styled(Drawer)`
    border-radius: 1.5rem;
    margin: 1.5rem;
    box-sizing: border-box;
    width: 350px;
    height: calc(100% - 3rem);
    min-width: 350px;
    min-height: calc(100% - 3rem);
    max-height: calc(100% - 3rem);
    box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.07);

    > div {
        box-sizing: border-box;
    }

    > div[id^="drawer-content"] {
        padding: 0.5rem 0 0;
        margin: 0 0 1rem;
    }
`;

export const Header = styled.div`
    display: flex;
    margin: 1rem 1.5rem 0;
    padding-bottom: 1rem;
    flex-direction: column;
    box-siing: border-box;
    justify-content: center;
    align-items: flex-start;
    gap: 0.125rem;
    border-bottom: 1px solid #E2E1E3;
`;

export const Title = styled.h3`
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 800;
    line-height: 1.75rem;
    color: #1E1E1E;
`;

export const SubTitle = styled.p`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    color: #4B465C;
`;

export const CustomersContainer = styled.div`
    width: 100%;
    height: 100%;
`;

export const CustomerInfoContainer = styled.div<{ disabled?: boolean }>`
    display: flex;
    padding: 0.5rem 0rem;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem 1.5rem;
    ${(props) => !props.disabled && css`
        &:hover {
            background: ${GREY_50};
        }
    `};
`;

export const CustomerInfoContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-self: stretch;
    overflow: hidden;
`;

export const CustomerName = styled.span`
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const CustomerPhone = styled.span`
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    pointer-events: none;
`;
