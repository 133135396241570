import { useEffect, useMemo, useState } from 'react';
import { Customer } from 'data/firestore/agent/customer/types';
import { EntityGet } from 'data/firestore/types';
import getCustomer from 'data/firestore/agent/customer/get';
import { useParams } from 'react-router-dom';

interface HookResult {
    isLoading?: boolean;
    customers: EntityGet<Customer>[];
}

const fetchCustomers = async (
    agentId: string,
    customersIds: string[],
): Promise<EntityGet<Customer>[]> => {
    if (!agentId) return [];

    const uniqueIds = Array.from(new Set(customersIds));
    const promises = await Promise.allSettled(
        uniqueIds.map((customerId) => getCustomer(agentId, customerId)),
    );

    const customersMap: Record<string, EntityGet<Customer> | null> = promises.reduce(
        (result, promiseResult, index) => {
            const customerId = uniqueIds[index];
            return {
                ...result,
                [customerId]: (
                    promiseResult.status === 'fulfilled'
                        ? promiseResult.value
                        : null
                ),
            };
        },
        {},
    );

    return customersIds.map(
        // eslint-disable-next-line max-len
        (customerId) => customersMap[customerId] ?? { id: customerId } as EntityGet<Customer>,
    );
};

const useResolveCustomers = (customersIds: string[]): HookResult => {
    const { agentId = '' } = useParams();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [customers, setCustomers] = useState<EntityGet<Customer>[]>([]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const customersList = await fetchCustomers(agentId, customersIds);
            setCustomers(customersList);
            setLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customersIds]);

    return useMemo(() => ({
        isLoading,
        customers,
    }), [customers, isLoading]);
};

export default useResolveCustomers;
