import styled from 'styled-components';
import { Textarea } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import Input from 'components/Input';
import UserAvatar from 'components/UserAvatar';
import { color } from 'theme';
import {
    GREY_800, DIVIDER, WHITE, GREY_50,
} from '../../constants';
import UnreadFilterPicker from './ConversationList/UnreadFilterPicker';

export const CloseButton = styled(ButtonIcon)`
    position: absolute;
    right: 0;
    top: 0;
    color: ${GREY_800};

    & svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
        font-size: 1.5rem !important;
    }
`;

export const FilterButton = styled(UnreadFilterPicker)`
    position: absolute;
    right: 3.25rem;
    top: 0;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 1.5rem;
    width: 380px;
    min-width: 380px;
    height: calc(100vh - 3rem);
    margin-top: 1.5rem;
    margin-right: 1.5rem;
    padding: 0;
    box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.07);
    z-index: 5000;
    overflow: hidden;
`;

export const Header = styled.div`
    display: flex;
    margin: 1rem 1rem 0 1rem;
    padding-bottom: 1rem;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid ${DIVIDER};
    position: relative;
`;

export const CustomerInfoContainer = styled.div`
    display: flex;
    width: 100%;
    margin-right: 4.25rem;
    max-width: calc(380px - 8rem);
`;

export const Title = styled.h2`
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 800;
    line-height: 1.75rem;
    display: flex;
    width: 100%;
    height: 3rem;
    align-items: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    position: relative;
`;

export const Footer = styled.div`
    display: flex;
    padding: 0rem 1rem 1rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    min-height: 1px;
`;

export const ChatInputContainer = styled.div`
    display: flex;
    padding: 1rem 0.5rem 0.5rem 1rem;
    justify-content: stretch;
    align-items: flex-end;
    gap: 0.5rem;
    flex: 1 0 0;
    border-radius: 0.75rem 0.75rem 2rem 0.75rem;
    border: 1px solid ${DIVIDER};
`;

export const SendButton = styled(ButtonIcon)`
    width: 3.5rem;
    height: 3.5rem;

    & svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
        font-size: 1.5rem !important;
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    flex: 1 0 0;
    border-radius: 2rem;
`;

export const MessageInput = styled(Textarea)`
    width: 100%;
    max-height: 460px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0;
    background-clip: unset;
    
    &::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
    & > div {
        border: none;
        border-radius: none;
        box-shadow: none;
        background: ${WHITE};
    }
    textarea,
    textarea[disabled] {
        max-height: 420px;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
        padding: 0;
        border-radius: 0;

        &:focus,
        &:active {
            border-radius: 0;
            padding: 0;
        }

    }     
`;

export const ActionsContainer = styled.div`
    display: flex;
    padding: 0.25rem 0rem;
    align-items: flex-start;
    align-self: stretch;
    border-top: 1px solid ${DIVIDER};
`;

export const LoadingContainer = styled.div`
    display: flex;
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem;
`;

export const EmptyMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 1.5rem 1.5rem 1.5rem;

    > div {
        padding: 0;
        padding-bottom: 0.5rem;
        
        > p {
            text-align: center;
        }
    }
`;

export const EmptyMessageTitle = styled.h3`
    font-size: 1rem;
    color: ${GREY_800};
    font-weight: 500;
`;

export const EmptyMessageEmoji = styled.span`
    font-size: 2.5rem;
`;

export const SearchInput = styled(Input)`
    width: 100%;

    span {
        left: 1rem;
    }

    svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
        font-size: 1.5rem !important;
    }

    input {
        background: ${GREY_50};
        border-color: ${GREY_50};
        height: 3rem;
        line-height: 3rem;
        padding: 0px 1rem 0px 3rem;

        &:focus {
            padding-left: 2.9375rem;
        }
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    width: 100%;
    justify-content: stretch;
`;

export const StyledSingleUserAvatar = styled(UserAvatar)`
    border: 2px solid transparent;
    border: 2px solid ${color('background.main')};
    pointer-events: none;
`;
