import { Spinner } from 'react-rainbow-components';
import trucateFilename from 'data/services/string/trucateFilename';
import {
    StyledContent,
    StyledContiner,
    StyledFileName,
    StyledIcon,
    StyledIconContainer,
    StyledInfo,
    StyledSpinnerContiner,
} from './styled';

interface Props {
    filename?: string | null;
    isLoading: boolean;
}

const Generic = ({ filename, isLoading }: Props) => {
    if (isLoading) {
        return (
            <StyledSpinnerContiner>
                <Spinner type="arc" variant="brand" />
            </StyledSpinnerContiner>
        );
    }
    return (
        <StyledContiner>
            <StyledIconContainer>
                <StyledIcon />
            </StyledIconContainer>
            <StyledContent>
                <StyledFileName>{trucateFilename(filename || '')}</StyledFileName>
                <StyledInfo>Download file.</StyledInfo>
            </StyledContent>
        </StyledContiner>
    );
};

export default Generic;
