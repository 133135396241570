import {
    useEffect, useRef, useState,
} from 'react';
import isScrollPositionAtMenuBottom from '../helpers/isScrollPositionAtMenuBottom';
import scrollTo from '../helpers/scrollTo';

const useArrowScrolling = <T extends HTMLElement>(enabled: boolean) => {
    const [showScrollUpArrow, setShowScrollUpArrow] = useState(false);
    const [showScrollDownArrow, setShowScrollDownArrow] = useState(false);
    const [referenceElement, setReferenceElement] = useState<T | null>(null);
    const scrollingTimer = useRef<NodeJS.Timer>();

    const stopScolling = () => {
        if (scrollingTimer.current) clearInterval(scrollingTimer.current);
    };

    const scrollUp = () => {
        stopScolling();
        if (referenceElement) {
            scrollingTimer.current = setInterval(() => {
                if (referenceElement.scrollTop > 0) {
                    referenceElement.scrollBy(0, -1);
                } else {
                    stopScolling();
                }
            }, 5);
        }
    };

    const scrollDown = () => {
        stopScolling();
        if (referenceElement) {
            scrollingTimer.current = setInterval(() => {
                if (!isScrollPositionAtMenuBottom(referenceElement)) {
                    referenceElement.scrollBy(0, 1);
                } else {
                    stopScolling();
                }
            }, 5);
        }
    };
    const updateScrollingArrows = () => {
        if (referenceElement) {
            setShowScrollUpArrow(referenceElement.scrollTop > 0);
            setShowScrollDownArrow(!isScrollPositionAtMenuBottom(referenceElement));
        }
    };

    useEffect(() => {
        if (enabled && referenceElement) {
            scrollTo(referenceElement, 0);
            updateScrollingArrows();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabled, referenceElement]);

    return {
        setRef: setReferenceElement,
        scrollUp,
        stopScolling,
        scrollDown,
        updateScrollingArrows,
        showScrollUpArrow,
        showScrollDownArrow,
    };
};

export default useArrowScrolling;
