import { useEffect, useRef } from 'react';
import { BaseProps } from 'types';

interface SVGRenderProps extends BaseProps {
    svgStr?: string
}

const SVGRender = ({
    className,
    svgStr = '',
}: SVGRenderProps) => {
    const ref = useRef<HTMLSpanElement>(null);
    useEffect(() => {
        if (ref.current) {
            ref.current.innerHTML = svgStr;
        }
    }, [svgStr]);
    return <span className={className} ref={ref} />;
};

export default SVGRender;
