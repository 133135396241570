import { useMemo } from 'react';
import { QueryKey } from 'react-query';

const useTwilioQueryKey = (
    key: unknown[],
    trigger: unknown,
): QueryKey => useMemo(
    () => [...key, Date.now()],
    // avoid to use useMemo for the key
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trigger, ...key],
);

export default useTwilioQueryKey;
