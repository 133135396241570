import { useCallback, useRef, useState } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import Label from 'react-rainbow-components/components/Input/label';
import { showAppNotification } from '@rainbow-modules/app';
import useUpdateAgentMutation from 'data/hooks/useUpdateAgentMutation';
import Button from 'components/Button';
import useAgentData from 'hooks/useAgentData';
import {
    Row, Container, InstructionsInput, StyledForm,
} from './styled';
import { FormFooter, StyledLoadingShape } from '../styled';
import { FormState } from '../types';

interface FormValues extends Record<string, unknown> {
    additionalInfo?: string;
}

interface AdditionalInfoProps {
    canEditAgent?: boolean
}

const checkFormModified = (formState: FormState<FormValues>): boolean => {
    const { pristine, valid } = formState;
    return pristine || !valid;
};

const AdditionalInfo = ({
    canEditAgent = false,
}: AdditionalInfoProps) => {
    const { agentId = '' } = useParams();
    const { agent, isLoading: isLoadingAgent } = useAgentData();
    const formRef = useRef();
    const [disableSubmit, setDisableSubmit] = useState(true);

    const {
        mutateAsync: updateAgent,
        isLoading: isUpdatingAgent,
    } = useUpdateAgentMutation(agentId as string);

    const handleSubmit = useCallback(
        async (values: FormValues) => {
            try {
                await updateAgent({
                    body: {
                        description: values.additionalInfo,
                    },
                });
                showAppNotification({
                    title: 'Success',
                    description: 'Your changes have been saved.',
                    icon: 'success',
                    timeout: 5000,
                });
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                formRef.current?.reset();
            } catch (error: any) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
        },
        [updateAgent],
    );

    return (
        <Container>
            <RenderIf isTrue={isLoadingAgent}>
                <StyledLoadingShape />
                <StyledLoadingShape />
                <StyledLoadingShape />
                <StyledLoadingShape />
            </RenderIf>
            <RenderIf isTrue={!isLoadingAgent}>
                <StyledForm
                    ref={formRef}
                    id="company-additional-info-form"
                    initialValues={{
                        additionalInfo: agent?.description,
                    }}
                    onSubmit={handleSubmit}
                >
                    <Label label="Company Description" required labelAlignment="left" />
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={InstructionsInput}
                        name="additionalInfo"
                        borderRadius="semi-rounded"
                        autoComplete="off"
                        required
                        readOnly={!canEditAgent}
                        disabled={isUpdatingAgent || isLoadingAgent}
                    />
                    <FormSpy
                        onChange={
                            (props) => setTimeout(
                                () => setDisableSubmit(checkFormModified(props)),
                                0,
                            )
                        }
                    />
                </StyledForm>
                <Row>
                    <FormFooter>
                        <Button
                            label="Save Change"
                            variant="brand"
                            form="company-additional-info-form"
                            type="submit"
                            borderRadius="semi-rounded"
                            disabled={!canEditAgent || disableSubmit}
                            isLoading={isUpdatingAgent}
                        />
                        <Button
                            label="Cancel"
                            variant="outline-brand"
                            form="company-info-form"
                            borderRadius="semi-rounded"
                            disabled={!canEditAgent || disableSubmit}
                            isLoading={isUpdatingAgent}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            onClick={() => formRef.current?.reset()}
                        />
                    </FormFooter>
                </Row>
            </RenderIf>
        </Container>
    );
};

export default AdditionalInfo;
