import styled, { css } from 'styled-components';
import { Modal } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import Button from 'components/Button';
import {
    GREY_800, GREY_400, GREY_300, GREY_100,
} from '../../constants';

export const ActionButton = styled(ButtonIcon)`
    width: 3rem;
    height: 3rem;
    gap: 1rem;

    svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
        font-size: 1.5rem !important;
    }
`;

export const StyledModal = styled(Modal)`
    width: 51.0625rem;
    flex-shrink: 0;
    max-height: unset;
`;
export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
`;

export const ModalTitle = styled.h1`
    font-size: 1.625rem;
    font-weight: 600;
    line-height: 2.25rem;
    color: ${GREY_800};
`;

export const ModalSubtitle = styled.div`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_400};
`;

export const ModalBody = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
`;

export const EnhanceActionsContainer = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 1.5rem;
`;

export const StyledButton = styled(Button)`
    padding: 0.75rem 1.25rem;
    gap: 0.5rem;

    > svg {
        color: ${GREY_300};
    }

    ${(props) => props.disabled && css`
        color: ${GREY_100} !important;

        > svg {
            color: ${GREY_100} !important;
        }
    `}
`;
