import { useCallback, useMemo } from 'react';
import { RenderIf } from 'react-rainbow-components';
import { useOpenModal } from '@rainbow-modules/hooks';
import UserAvatar from 'components/UserAvatar';
import { formatRelativeDate } from 'data/services/date/formatter';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import priceFormatter from 'data/services/price/formatter';
import getVariantFromScore from '../../helpers/getVariantFromScore';
import { OPPORTUNITY_DETAILS_DRAWER } from '../../../../constants';
import { OpportunityCard as OpportunityCardProps } from './types';
import {
    CardContainer, CardHeader, CardTitle, CardHeaderContent, Score,
    CardFooter, PriceLabel, DateLabel, StyledUserAvatar, StyledListTile,
} from './styled';
import { CustomerNotificationBadge } from '../styled';

const OpportunityCard = ({
    title,
    data,
}: OpportunityCardProps) => {
    const {
        id = '', firstName = '', lastName = '', alias = '', displayName: displayNameProp = '', photoURL,
    } = data?.customer || {};

    const [openModal] = useOpenModal(OPPORTUNITY_DETAILS_DRAWER);

    const displayName = useMemo(
        () => getDisplayName({
            firstName,
            lastName,
            alias,
            displayName: displayNameProp,
        }),
        [firstName, lastName, alias, displayNameProp],
    );

    const customerInitials = useMemo(() => {
        if (displayName === 'Unknown') return null;
        return getInitials(displayName);
    }, [displayName]);

    const ownerInitials = useMemo(() => {
        if (data?.owner) {
            const ownerDisplayName = getDisplayName(data?.owner);
            if (ownerDisplayName !== 'Unknown') return getInitials(ownerDisplayName);
        }
        return null;
    }, [data?.owner]);

    const amount = useMemo(
        () => priceFormatter('usd').format((data?.amount || 0) / 100),
        [data?.amount],
    );

    const relativeDateValue = useMemo(
        () => data?.updatedAt && formatRelativeDate(data.updatedAt),
        [data?.updatedAt],
    );

    const handleOpenDetails = useCallback(
        () => openModal({ data }),
        [data, openModal],
    );

    return (
        <CardContainer>
            <CardHeader>
                <Score
                    variant={getVariantFromScore(data?.score)}
                    value={data?.score || 0}
                />
                <CardHeaderContent>
                    <CardTitle
                        type="button"
                        onClick={handleOpenDetails}
                    >
                        {title}
                    </CardTitle>
                    <StyledListTile
                        icon={(
                            <CustomerNotificationBadge
                                overlap="circle"
                                position="bottom-right"
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                isHidden={!data?.customer?.hasNewMessage}
                            >
                                <UserAvatar
                                    size="x-small"
                                    initials={customerInitials}
                                    src={photoURL}
                                    value={id}
                                />
                            </CustomerNotificationBadge>
                        )}
                        title={displayName}
                    />
                </CardHeaderContent>
            </CardHeader>
            <CardFooter>
                <PriceLabel>{amount}</PriceLabel>
                <div className="rainbow-flex rainbow-align_end">
                    <DateLabel>{`Updated ${relativeDateValue}`}</DateLabel>
                    <RenderIf isTrue={data?.owner}>
                        <StyledUserAvatar
                            initials={ownerInitials}
                            value={data?.owner?.uid}
                            src={data?.owner?.photoURL}
                        />
                    </RenderIf>
                </div>
            </CardFooter>
        </CardContainer>
    );
};

export default OpportunityCard;
