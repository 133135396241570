import { FeatureWrapper, StyledSpan, IconContainer } from './styled';
import { FeatureIcon } from '../styled';

interface Props {
    row?: Record<string, unknown>,
    value?: string;
}

const FeatureColumn = ({ row, value }: Props) => (
    <FeatureWrapper>
        <IconContainer>
            <FeatureIcon svgStr={row?.icon as string} />
        </IconContainer>
        <StyledSpan>
            {value}
        </StyledSpan>
    </FeatureWrapper>
);

export default FeatureColumn;
