import styled from 'styled-components';
import { LoadingShape } from 'react-rainbow-components';
import LinkWithQuery from 'components/LinkWithQuery';
import { color } from 'theme';
import { GREY_800, GREY_900 } from '../../../constants';

export const Container = styled.div`
    display: flex;
    width: 100%;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;

    > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const Title = styled.span`
    color: ${GREY_900};
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
`;

export const Subtitle = styled.span`
    color: ${GREY_800};
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 1.5;
`;

export const IconContainer = styled.div`
    flex-shrink: 0;
    margin-right: 10px;
`;

export const StyledAvatarLoadingShape = styled(LoadingShape)`
    position: relative;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    overflow: hidden;

    > div {
        width: 100%;
        height: 100%;
        min-width: auto;
        min-height: auto;
    }
`;

export const StyledLink = styled(LinkWithQuery)`
    color: ${color('brand.main')};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
        color: ${color('brand.dark')};
    }
`;
