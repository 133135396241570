import { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ResponsiveFunnel } from '@nivo/funnel';
import { RenderIf, Spinner } from 'react-rainbow-components';
import { DateTime } from 'luxon';
import useHttpQuery from 'data/firestore/useHttpQuery';
import getColorSteps from 'helpers/getColorSteps';
import useAgentData from 'hooks/useAgentData';
import DateFilter from './dateFilter';
import { Content, StyledInsightSectionContainer } from '../styled';
import FunnelLabels from './labels';
import context, { Context } from '../context';

interface PlotValue {
    id: string;
    label: string;
    value: number;
    percent: number;
}

interface PlotData {
    values: PlotValue[];
}

const ConversionJourneyInsights = () => {
    const { agentId = '' } = useParams();
    const { agent, isLoading: isLoadingAgent } = useAgentData();
    const { pipelines, isLoading: isLoadingPipelines } = useContext<Context>(context);
    const [dateFilter, setDateFilter] = useState<string | Date[]>('last-month');

    const { from, to } = useMemo(
        () => {
            const timeZone = agent?.companyAddress.timezone
                || Intl.DateTimeFormat().resolvedOptions().timeZone;

            const now = DateTime.now().setZone(timeZone);

            if (Array.isArray(dateFilter)) {
                const [fromDate, toDate] = dateFilter;
                return {
                    from: DateTime.fromJSDate(fromDate).setZone(timeZone, { keepLocalTime: true }).startOf('day'),
                    to: toDate && DateTime.fromJSDate(toDate).setZone(timeZone, { keepLocalTime: true }).endOf('day'),
                };
            }

            if (dateFilter === 'last-month') {
                return {
                    from: now.minus({ month: 1 }).startOf('month'),
                    to: now.minus({ month: 1 }).endOf('month'),
                };
            }

            if (dateFilter === 'current-year') {
                return {
                    from: DateTime.now().setZone(timeZone).startOf('year'),
                    to: DateTime.now().setZone(timeZone).endOf('year'),
                };
            }

            if (dateFilter === 'last-year') {
                return {
                    from: DateTime.now().setZone(timeZone).minus({ months: 12 }).startOf('year'),
                    to: DateTime.now().setZone(timeZone).minus({ months: 12 }).endOf('year'),
                };
            }

            return {
                from: now.startOf('month'),
                to: now.endOf('month'),
            };
        },
        [agent, dateFilter],
    );

    const { data: plotData, isLoading: isLoadingData } = useHttpQuery<PlotData>({
        key: [
            'insight-opportunities-conversion-journey',
            agentId,
            pipelines[0]?.id,
            from.toJSDate().toISOString().slice(0, 10),
            to?.toJSDate().toISOString().slice(0, 10),
        ],
        pathname: `/agents/${agentId}/insights/opportunities/conversion-journey/${pipelines[0]?.id}?&start=${encodeURIComponent(from.toISO() || '')}&end=${encodeURIComponent(to?.toISO() || '')}`,
        queryOptions: {
            enabled: Boolean(agent?.id && pipelines[0]?.id && from.toISO() && to?.toISO()),
            retryOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            gcTime: 24 * 60 * 60 * 1000,
            staleTime: 24 * 60 * 60 * 1000,
        },
    });

    const isLoading = isLoadingAgent || isLoadingPipelines || isLoadingData;

    return (
        <StyledInsightSectionContainer
            height="29.875rem"
            label="Conversion Journey"
            description="Track the journey from prospect to customer, highlighting key conversion rates and stages to optimize your sales process."
            filter={(
                <DateFilter
                    value={dateFilter}
                    onChange={setDateFilter}
                    disabled={isLoading}
                />
            )}
        >
            <Content>
                <RenderIf isTrue={isLoading}>
                    <Spinner size="large" variant="brand" type="arc" />
                </RenderIf>
                <RenderIf isTrue={!isLoading && plotData?.values.length}>
                    <ResponsiveFunnel
                        data={(plotData?.values || []) as any}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}
                        direction="horizontal"
                        shapeBlending={0.68}
                        valueFormat=" >-.2s"
                        colors={getColorSteps('#7367F0', '#8ED8A7', plotData?.values.length || 1)}
                        borderWidth={20}
                        borderOpacity={0.25}
                        enableLabel={false}
                        labelColor={{ theme: 'labels.text.fill' }}
                        enableBeforeSeparators
                        beforeSeparatorLength={80}
                        currentPartSizeExtension={10}
                        currentBorderWidth={20}
                        motionConfig="wobbly"
                    />
                    <FunnelLabels data={plotData?.values as unknown as Record<string, unknown>[]} />
                </RenderIf>
            </Content>
        </StyledInsightSectionContainer>
    );
};

export default ConversionJourneyInsights;
