import Pencil from 'components/icons/pencil3';
import Trash from 'components/icons/trash';
import {
    MessageActions, MessageContainer, MessageContent,
    MessageTitle, MessageTitleContainer, StyledButtonIcon,
} from './styled';

interface MessageProps {
    title: string;
    content: string;
    onDeleteClick?: () => void
    onEditClick?: () => void
}

const Message = ({
    title,
    content,
    onEditClick = () => {},
    onDeleteClick = () => {},
}: MessageProps) => (
    <MessageContainer>
        <MessageTitleContainer>
            <MessageTitle>{title}</MessageTitle>
            <MessageContent>{content}</MessageContent>
        </MessageTitleContainer>
        <MessageActions>
            <StyledButtonIcon
                borderRadius="semi-square"
                size="small"
                icon={<Pencil />}
                onClick={onEditClick}
            />
            <StyledButtonIcon
                borderRadius="semi-square"
                size="small"
                icon={<Trash />}
                onClick={onDeleteClick}
            />
        </MessageActions>
    </MessageContainer>
);

export default Message;
