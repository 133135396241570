import { IconProps } from 'types';

const Undo = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <title>{title}</title>
        <path d="M8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071C10.0976 13.3166 10.0976 12.6834 9.70711 12.2929L8.29289 13.7071ZM5 9L4.29289 8.29289C3.90237 8.68342 3.90237 9.31658 4.29289 9.70711L5 9ZM9.70711 5.70711C10.0976 5.31658 10.0976 4.68342 9.70711 4.29289C9.31658 3.90237 8.68342 3.90237 8.29289 4.29289L9.70711 5.70711ZM5 8C4.44772 8 4 8.44772 4 9C4 9.55228 4.44772 10 5 10L5 8ZM16 17L16 18L16 17ZM15 16C14.4477 16 14 16.4477 14 17C14 17.5523 14.4477 18 15 18L15 16ZM9.70711 12.2929L5.70711 8.29289L4.29289 9.70711L8.29289 13.7071L9.70711 12.2929ZM5.70711 9.70711L9.70711 5.70711L8.29289 4.29289L4.29289 8.29289L5.70711 9.70711ZM5 10L16 10L16 8L5 8L5 10ZM16 10C17.6569 10 19 11.3431 19 13L21 13C21 10.2386 18.7614 8 16 8L16 10ZM19 13C19 14.6569 17.6569 16 16 16L16 18C18.7614 18 21 15.7614 21 13L19 13ZM16 16L15 16L15 18L16 18L16 16Z" fill="currentColor" />
    </svg>
);
export default Undo;
