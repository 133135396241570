import { RenderIf } from 'react-rainbow-components';
import usePipelineStageInsightsDetails from '../hooks/usePipelineStageInsightsDetails';
import {
    StyledDrawer, Header, Title, SubTitle,
} from './styled';
import CustomersList from './customersList';

const PipelineStageInsightsDetailsDrawer = ({ drawerId = '' }: { drawerId: string }) => {
    const {
        isOpen, data, connectedDrawerProps, close,
    } = usePipelineStageInsightsDetails(drawerId);

    const { title, subtitle, customers } = data || {};

    return (
        <StyledDrawer
            {...connectedDrawerProps}
            isOpen={isOpen}
            onRequestClose={() => close()}
            slideFrom="right"
            size="small"
            header={(
                <Header>
                    <Title>{title as string}</Title>
                    <SubTitle>{subtitle as string}</SubTitle>
                </Header>
            )}
        >
            <RenderIf isTrue={isOpen}>
                <CustomersList customers={customers as string[]} />
            </RenderIf>
        </StyledDrawer>
    );
};

export default PipelineStageInsightsDetailsDrawer;
