import { BoardCard } from '../types';
import { StyledCard } from './styled';

const DefaultCard = ({
    title,
}: BoardCard) => (
    <StyledCard>
        {title}
    </StyledCard>
);

export default DefaultCard;
