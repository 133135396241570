import { useMemo, useState } from 'react';
import { query, where } from 'firebase/firestore';
import { RenderIf } from 'react-rainbow-components';
import { isEmpty } from '@rainbow-modules/validation';
import { MagnifyingGlass } from '@rainbow-modules/icons';
import { useOpenModal } from '@rainbow-modules/hooks';
import ButtonIcon from 'components/ButtonIcon';
import EmptyMessage from 'components/EmptyMessage';
import Gradient from 'components/Gradient';
import Plus from 'components/icons/plus';
import LoadingShape from 'components/LoadingShape';
import useFrequentMessages from 'data/firestore/agent/frequentmessage/useCollection';
import useManageFrequentMessages from 'data/hooks/useManageFrequentMessages';
import { ADD_EDIT_FREQUENT_MESSAGE } from '../../../constants';
import DrawerHeader from '../drawerHeader';
import { Container, SearchInput } from '../styled';
import {
    ListHeader, ListTitle,
    MessagesListContainer, Messages,
} from './styled';
import { MessageContext } from '../types';
import Message from './message';

interface MessagesListProps {
    agentId: string;
    context: MessageContext;
    onRequestClose?: () => void;
}

const FrequentMessagesList = ({
    onRequestClose = () => {},
    context,
    agentId,
}: MessagesListProps) => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const { data: messages = [], isLoading } = useFrequentMessages(agentId, {
        listQueryFn: (ref) => query(ref, where('context', '==', context)),
        disabled: !agentId,
    });

    const [, closeCreateEditDrawer] = useOpenModal(ADD_EDIT_FREQUENT_MESSAGE);

    const {
        create: createFrequentMessage,
        edit: editFrequentMessage,
        remove: removeFrequentMessage,
    } = useManageFrequentMessages({
        agentId,
        context,
        onFrequentMessageCreated: closeCreateEditDrawer,
    });
    const drawerTitle = context === 'messages' ? 'Manage Frequent Messages' : 'Manage Frequent Notes';
    const drawerSubtitle = (
        context === 'messages'
            ? 'Create, edit, or delete your frequently used messages to save time and maintain consistency in your communications.'
            : 'Create, edit, or delete your frequently used notes to save time and maintain consistency in your communications.'
    );
    const addTooltipButton = context === 'messages' ? 'Create Frequent Message' : 'Create Frequent Note';
    const listTitle = context === 'messages' ? 'All Frequent Messages' : 'All Frequent Notes';
    const searchPlaceholderText = context === 'messages' ? 'Find frequent messages...' : 'Find frequent notes...';

    const messagesList = useMemo(() => {
        if (messages.length === 0) {
            return (
                <EmptyMessage
                    message={(
                        context === 'messages'
                            ? 'There is no messages'
                            : 'There is no notes'
                    )}
                />
            );
        }

        const regex = new RegExp(searchQuery, 'gi');
        const filteredMessges = (
            isEmpty(searchQuery)
                ? messages
                : messages.filter(
                    (message) => regex.test(message.title) || regex.test(message.content),
                )
        );

        if (filteredMessges.length === 0) {
            return (
                <EmptyMessage
                    message={(
                        context === 'messages'
                            ? `No messages matching ${searchQuery} were found`
                            : `No notes matching ${searchQuery} were found`
                    )}
                />
            );
        }

        return filteredMessges.map((message) => (
            <Message
                key={message.id}
                title={message.title}
                content={message.content}
                onEditClick={() => editFrequentMessage(message)}
                onDeleteClick={() => removeFrequentMessage(message.id)}
            />
        ));
    }, [messages, context, searchQuery, removeFrequentMessage, editFrequentMessage]);

    return (
        <Container>
            <DrawerHeader
                title={drawerTitle}
                subtitle={drawerSubtitle}
                onRequestClose={onRequestClose}
            />
            <SearchInput
                icon={<MagnifyingGlass />}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={searchPlaceholderText}
            />
            <MessagesListContainer>
                <ListHeader>
                    <ListTitle>{listTitle}</ListTitle>
                    <ButtonIcon
                        icon={<Plus />}
                        tooltip={addTooltipButton}
                        onClick={() => createFrequentMessage({})}
                    />
                </ListHeader>
                <Messages>
                    <RenderIf isTrue={isLoading}>
                        <LoadingShape />
                    </RenderIf>
                    <RenderIf isTrue={!isLoading}>
                        {messagesList}
                    </RenderIf>
                </Messages>
                <Gradient position="bottom" />
            </MessagesListContainer>
        </Container>
    );
};

export default FrequentMessagesList;
