import {
    useCallback, useContext, useEffect, useMemo,
} from 'react';
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import { JsonInput } from '@rainbow-modules/forms';
import { Field, useForm, useFormState } from 'react-final-form';
import Form from 'components/InstructionTestingFormStep';
import context from 'components/InstructionTestingLayout/context';
import Button from 'components/Button';
import tryParseJson from 'data/services/string/parseJson';
import { InstructionTestStepComponentProps } from 'components/InstructionTestingLayout/types';
import { JSONSchema } from 'data/firestore/globals';
import { Llm as Responder } from 'data/firestore/agent/llm/types';
import {
    FieldsContainer, FieldLabel, Row, ButtonsContainer,
} from './styled';
import { ResponderInstructionTestingContext } from '../types';

const validateInput = (
    message: string,
    inputSchema?: JSONSchema | null,
) => (value: string) => {
    if (!inputSchema) return undefined;
    if (value) {
        try {
            const json = (
                typeof value === 'string'
                    ? JSON.parse(value)
                    : value
            );
            const ajv = new Ajv({ allErrors: true });
            addFormats(ajv);
            const isValid = ajv.validate(inputSchema, json);
            if (isValid) return undefined;
            return ajv.errorsText(ajv.errors) || message;
        } catch (error) {
            // no catch
        }
    }
    return message;
};

const mapFormValuesToContext = (values: Record<string, unknown>) => {
    const { input } = values;
    if (input) {
        return typeof input === 'string' ? tryParseJson(input as string) : input;
    }
    return {};
};

const Fields = ({ config }: { config?: Responder }) => {
    const { initialValues } = useFormState();
    const { change } = useForm();

    useEffect(() => {
        change('input', initialValues.inputValues?.input || '{}');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FieldsContainer>
            <Row>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={JsonInput}
                    name="input"
                    label={<FieldLabel>Input</FieldLabel>}
                    labelAlignment="left"
                    validate={validateInput('Invalid Json Data', config?.inputSchema)}
                />
            </Row>
        </FieldsContainer>
    );
};

const InputView = ({
    onChange = () => {},
}: InstructionTestStepComponentProps) => {
    const {
        inputValues, responderConfig,
    } = useContext<ResponderInstructionTestingContext>(context);

    const initialValues = useMemo(
        () => ({ inputValues }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const handleSubmit = useCallback(
        async (values: Record<string, unknown>) => {
            const newContext = mapFormValuesToContext(values);
            onChange({ context: newContext, formValues: values });
        },
        [onChange],
    );

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={initialValues}
        >
            <Fields config={responderConfig} />
            <ButtonsContainer>
                <Button variant="brand" type="submit">Submit</Button>
            </ButtonsContainer>
        </Form>
    );
};

export default InputView;
