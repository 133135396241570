import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { showAppNotification } from '@rainbow-modules/app';
import { RenderIf, Tabset } from 'react-rainbow-components';
import Badge from 'components/Badge';
import ButtonIcon from 'components/ButtonIcon';
import CallTranscriptionHighlights from 'components/CallTranscriptionHighlights';
import CustomerActivity from 'components/CustomerActivity';
import Close from 'components/icons/close';
import ListIcon from 'components/icons/list';
import Note from 'components/icons/note';
import CustomerInformation from 'components/OpportunityDetailsDrawer/CustomerInformation';
import TaskSummary from 'components/TasksInCategory/Task/Summary';
import TaskTitle from 'components/TasksInCategory/Task/Title';
import TaskStatusActions from 'components/TaskStatusActions';
import { Customer } from 'data/firestore/agent/customer/types';
import { Inbox } from 'data/firestore/inbox/types';
import { Session } from 'data/firestore/session/types';
import { EntityGet } from 'data/firestore/types';
import useCustomerActivity from 'data/hooks/useCustomerActivity';
import useSendNote from 'data/hooks/useSendNote';
import Transcript from 'pages/History/ConversationDetails/Transcript';
import Phone from 'components/icons/phone';
import Light from 'components/icons/light';
import {
    DetailsContainer,
    DetailsContent,
    Header,
    HeaderActions,
    CustomerContainer,
    SummaryContainer,
    TabsetContainer,
    StyledTab,
    TabLabel,
    Row,
    NotesContainer,
    StyledSendNoteForm,
    Gradient,
    MainContent,
    Line,
} from './styled';

interface Params {
    data?: {
        task?: EntityGet<Inbox> | null;
        session?: EntityGet<Session>;
        summary?: string;
        customer?: EntityGet<Customer>;
    };
    // eslint-disable-next-line react/no-unused-prop-types
    isLoading?: boolean;
    onRequestClose?: () => void;
}

const TaskDetails = ({
    data,
    isLoading,
    onRequestClose = () => {},
}: Params) => {
    const { agentId = '' } = useParams();
    const [isSendingNote, setSendingNote] = useState(false);
    const [activeTab, setActiveTab] = useState('highlights');

    const isSolved = !!data?.task?.solvedBy;
    const isArchived = !!data?.task?.removedBy;

    const [messages, isLoadingMessages] = useCustomerActivity(
        agentId,
        (data?.customer?.id || ''),
        {
            disabled: !agentId || !data?.customer?.id,
            track: [agentId, data?.customer?.id],
        },
    );

    const { mutateAsync: sendNote } = useSendNote();

    const handleSendNote = useCallback(
        async (note: string, files: File[]) => {
            setSendingNote(true);
            try {
                await sendNote({
                    agentId,
                    customerId: data?.customer?.id,
                    inboxId: data?.task?.id,
                    note,
                    files,
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            setSendingNote(false);
        },
        [agentId, data?.customer?.id, data?.task?.id, sendNote],
    );

    const noteMessages = useMemo(
        () => messages.filter((message) => message.type === 'note' && message.inboxId === data?.task?.id),
        [messages, data?.task?.id],
    );

    return (
        <DetailsContainer>
            <Header>
                <ButtonIcon icon={<Close />} onClick={onRequestClose} />
                <HeaderActions>
                    <TaskStatusActions
                        taskId={data?.task?.id}
                        customer={data?.customer as unknown as Record<string, unknown>}
                        isArchived={isArchived}
                        isSolved={isSolved}
                    />
                </HeaderActions>
            </Header>
            <DetailsContent>
                <TaskTitle
                    label={data?.task?.title}
                    priority={data?.task?.priority}
                    date={data?.task?.createdAt}
                />
                <MainContent>
                    <CustomerContainer>
                        <CustomerInformation
                            agentId={agentId}
                            customerId={data?.customer?.id}
                            data={data?.customer}
                        />
                    </CustomerContainer>
                    <Line />
                    <SummaryContainer>
                        <TaskSummary
                            header="Sara's got the highlights for you"
                            summary={data?.summary}
                            date={data?.task?.createdAt}
                            showDate
                            hideDetailsLink
                        />
                    </SummaryContainer>
                </MainContent>
                <TabsetContainer>
                    <Tabset
                        variant="line"
                        activeTabName={activeTab}
                        onSelect={(e, selectedTab) => setActiveTab(selectedTab)}
                    >
                        <StyledTab
                            name="highlights"
                            label={(
                                <TabLabel>
                                    <Light />
                                    Highlights
                                </TabLabel>
                            )}
                        />
                        <StyledTab
                            name="details"
                            label={(
                                <TabLabel>
                                    <Phone />
                                    Call Details
                                </TabLabel>
                            )}
                        />
                        <StyledTab
                            name="activity"
                            label={(
                                <TabLabel>
                                    <ListIcon />
                                    Activity
                                </TabLabel>
                            )}
                        />
                        <StyledTab
                            name="notes"
                            label={(
                                <TabLabel>
                                    <Note />
                                    Notes
                                    <RenderIf isTrue={noteMessages.length > 0}>
                                        <Badge size="small">{noteMessages.length}</Badge>
                                    </RenderIf>
                                </TabLabel>
                            )}
                        />
                    </Tabset>
                </TabsetContainer>
                <Row>
                    {activeTab === 'highlights' && (
                        <CallTranscriptionHighlights
                            sessionId={data?.session?.id}
                            recordingId={data?.session?.recordingId}
                            from={data?.customer}
                            status={data?.session?.status}
                            emptyMessage="No highlighted messages"
                            markHighlightedText
                        />
                    )}
                    {activeTab === 'details' && (
                        <Transcript
                            sessionId={data?.session?.id}
                            recordingId={data?.session?.recordingId}
                            from={data?.customer}
                            status={data?.session?.status}
                        />
                    )}
                    {activeTab === 'activity' && !isLoading && (
                        <CustomerActivity
                            agentId={agentId}
                            isLoading={isLoadingMessages}
                            messages={messages}
                            context="inbox"
                        />
                    )}
                    {activeTab === 'notes' && (
                        <div className="rainbow-m-bottom_large">
                            <CustomerActivity
                                agentId={agentId}
                                isLoading={isLoadingMessages}
                                messages={noteMessages}
                                context="opportunity"
                                emptyMessage="No notes have been sent"
                            />
                            <NotesContainer>
                                <Gradient />
                                <StyledSendNoteForm
                                    onSend={handleSendNote}
                                    disableSend={isSendingNote}
                                />
                            </NotesContainer>
                        </div>
                    )}
                </Row>
            </DetailsContent>
        </DetailsContainer>
    );
};

export default TaskDetails;
