import { DateTime } from 'luxon';
import { Type } from 'components/DateFilter/types';

const formatLabel = ({
    label,
    unit,
    timeZone = '',
}: {
    label: string,
    unit: Type,
    timeZone?: string;
}) => {
    const date = DateTime.fromISO(label, { zone: timeZone });

    if (unit === 'month') return date.toFormat('MMM');

    if (unit === 'day' && !date.hasSame(DateTime.now(), 'year')) return date.toFormat('LLL d, yyyy');

    return date.toFormat('LLL d');
};

export default formatLabel;
