import { useCallback, useRef, useState } from 'react';
import Download from 'components/icons/download';
import HiddenAnchor from 'components/HiddenAnchor';
import { FileData } from './types';
import { StyledButtonIcon } from './styled';

interface Props {
    file?: FileData
}

const DownloadButton = ({ file }: Props) => {
    const { url = '', name, customMetadata } = file || {};
    const { originalName } = customMetadata || {};
    const hiddenLinkRef = useRef<HTMLAnchorElement>(null);
    const [isDownloading, setIsDownloading] = useState(false);

    const download = useCallback(async () => {
        if (!hiddenLinkRef.current || !url) return;

        setIsDownloading(true);
        const originalExt = originalName.split('.').pop();
        try {
            const res = await fetch(url);
            const blob = await res.blob();
            const downloadUrl = URL.createObjectURL(blob);
            hiddenLinkRef.current.href = downloadUrl;
            hiddenLinkRef.current.download = `${name}.${originalExt}`;
            hiddenLinkRef.current.click();
        } catch (error) {
            console.error('Error downloading file', error);
        }
    }, [name, originalName, url]);

    return (
        <>
            <HiddenAnchor ref={hiddenLinkRef} />
            <StyledButtonIcon
                icon={<Download />}
                tooltip="Download File"
                variant="border-filled"
                onClick={download}
                disabled={isDownloading}
                shaded
            />
        </>
    );
};

export default DownloadButton;
