import { useCallback } from 'react';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import Trash from 'components/icons/trash';
import ModalBody from 'components/ModalBody';
import { Frequentmessage } from 'data/firestore/agent/frequentmessage/types';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { ADD_EDIT_FREQUENT_MESSAGE } from '../../constants';

const mapDataToFormValues = (data: Frequentmessage) => ({
    title: data.title,
    content: data.content,
    context: data.context,
});

const useManageFrequentMessages = ({
    agentId,
    context,
    onFrequentMessageCreated = () => {},
    onFrequentMessageDeleted = () => {},
} : {
    agentId: string;
    context: Frequentmessage['context'],
    onFrequentMessageCreated?: (id: string) => void
    onFrequentMessageDeleted?: () => void;
}) => {
    const [
        openFrequentMessageForm,
        closeFrequentMessageForm,
    ] = useOpenModal(ADD_EDIT_FREQUENT_MESSAGE);

    const { mutateAsync: createOpportuniity } = useHttpMutation({
        method: 'POST',
        pathname: `/agents/${agentId}/frequent-messages`,
        onSuccess: closeFrequentMessageForm,
    });

    const { mutateAsync: updateFrequentMessage } = useHttpMutation({
        method: 'PATCH',
        onSuccess: closeFrequentMessageForm,
    });

    const { mutateAsync: deleteFrequentMessage } = useHttpMutation({
        method: 'DELETE',
    });

    const handleCreateFrequentMessage = useCallback(
        (initialValues: Record<string, unknown> = {}) => openFrequentMessageForm({
            initialValues: {
                context,
                ...initialValues,
            },
            agentId,
            onSubmit: async (values: Record<string, unknown>) => {
                showAppSpinner();
                try {
                    const { id } = await createOpportuniity({
                        body: values,
                    }) as Frequentmessage;
                    onFrequentMessageCreated(id);
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [agentId, context, createOpportuniity, onFrequentMessageCreated, openFrequentMessageForm],
    );

    const handleEditFrequentMessage = useCallback(
        (data: Frequentmessage) => openFrequentMessageForm({
            id: data?.id,
            agentId,
            mode: 'edit',
            initialValues: mapDataToFormValues(data),
            onSubmit: async (values: Record<string, unknown>) => {
                const { title, content } = values;
                showAppSpinner();
                try {
                    await updateFrequentMessage({
                        pathname: `/agents/${agentId}/frequent-messages/${data.id}`,
                        body: {
                            title,
                            content,
                        },
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [agentId, openFrequentMessageForm, updateFrequentMessage],
    );

    const handleDeleteFrequentMessage = useCallback(
        async (messageId: string) => {
            const result = await confirmModal({
                header: '',
                variant: 'destructive',
                question: (
                    <ModalBody
                        variant="destructive"
                        icon={<Trash />}
                        title={(context === 'messages' ? 'Remove frequent message?' : 'Remove frequent note?')}
                        description="Once this action is confirmed, it cannot be undone."
                    />
                ),
                borderRadius: 'semi-square',
                okButtonLabel: 'Remove',
                cancelButtonLabel: 'Cancel',
            });

            if (!result) {
                return;
            }

            showAppSpinner();
            try {
                await deleteFrequentMessage({
                    pathname: `/agents/${agentId}/frequent-messages/${messageId}`,
                });
                onFrequentMessageDeleted();
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [agentId, context, deleteFrequentMessage, onFrequentMessageDeleted],
    );

    return {
        create: handleCreateFrequentMessage,
        edit: handleEditFrequentMessage,
        remove: handleDeleteFrequentMessage,
    };
};

export default useManageFrequentMessages;
