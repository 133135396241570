import { ReactNode } from 'react';
import { RenderIf } from 'react-rainbow-components';
import {
    EmptyMessageContainer, EmptyMessageDescription, EmptyMessageEmoji, EmptyMessageTitle,
} from './styled';

interface Params {
    className?: string;
    message: string | ReactNode;
    emoji?: string;
    title?: string;
    size?: 'small' | 'medium';
}

const EmptyMessage = ({
    className,
    message,
    emoji,
    title,
    size,
}: Params) => {
    if (typeof message === 'string') {
        return (
            <EmptyMessageContainer className={className}>
                <RenderIf isTrue={!!emoji}>
                    <EmptyMessageEmoji size={size}>
                        {emoji}
                    </EmptyMessageEmoji>
                </RenderIf>
                <RenderIf isTrue={!!title}>
                    <EmptyMessageTitle size={size}>
                        {title}
                    </EmptyMessageTitle>
                </RenderIf>
                <EmptyMessageDescription>{message}</EmptyMessageDescription>
            </EmptyMessageContainer>
        );
    }

    return (
        <div className={className}>
            {message}
        </div>
    );
};

export default EmptyMessage;
