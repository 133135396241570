import React from 'react';

export interface Context {
    columnEditMode: boolean;
}

const context = React.createContext<Context>({
    columnEditMode: false,
});

export const { Provider, Consumer } = context;

export default context;
