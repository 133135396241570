import { ReactNode } from 'react';
import { BarDatum, BarTooltipProps } from '@nivo/bar';
import Facebook from 'components/icons/facebook';
import InstagramIcon from 'components/icons/instagram';
import { TooltipContainer } from './styled';

const iconsMap: Record<string, ReactNode> = {
    facebook: <Facebook />,
    instagram: <InstagramIcon />,
};

const BarTooltip = ({ value, id }: BarTooltipProps<BarDatum>) => (
    <TooltipContainer>
        <h2>AI-sent messages on:</h2>
        <div>
            {iconsMap[id]}
            <span>{`${id}: `}</span>
            <b>{value}</b>
        </div>
        <p>Click to see details</p>
    </TooltipContainer>
);

export default BarTooltip;
