import UserAvatar from 'components/UserAvatar';
import { Customer } from 'data/firestore/globals';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import {
    CustomerInfoContainer,
    CustomerInfoContent,
    CustomerName,
    CustomerPhone,
} from './styled';

interface CustomerInfoProps {
    data: Partial<Customer>;
    onClick?: () => void;
}

const CustomerInfo = ({
    data: customerData,
    onClick = () => {},
}: CustomerInfoProps) => {
    const customerName = getDisplayName(customerData);
    const contactInfo = formatIntlPhoneNumber(customerData?.phoneNumber as string)
        || customerData.email as string;

    return (
        <CustomerInfoContainer onClick={onClick}>
            <UserAvatar
                initials={getInitials(customerData as unknown as Record<string, unknown>)}
                value={customerData?.id}
                src={customerData?.photoURL}
            />
            <CustomerInfoContent>
                <CustomerName>{customerName}</CustomerName>
                <CustomerPhone>{contactInfo}</CustomerPhone>
            </CustomerInfoContent>
        </CustomerInfoContainer>
    );
};

export default CustomerInfo;
