import { useMemo } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import Plus from 'components/icons/plus';
import SidebarShrinkIcon from 'components/icons/sidebarShrink';
import {
    ColumnContainer, ColumnContent, ColumnFooter, ColumnHeader, ColumnHeaderActions,
    StyledButtonIcon, ColumnCards, StyledGradient,
    StyledVirtuoso,
    CardContainer,
} from './styled';
import {
    BaseProps, BoardCard, BoardColumn,
    KanbanBoardCardComponent,
} from '../types';
import KanbandBoardCard from './card';
import ColumnTitle from './columnTitle';
import DefaultCard from './defaultCardComponent';

const getVirtuosoItemContent = (
    cardComponent?: KanbanBoardCardComponent,
// eslint-disable-next-line react/function-component-definition
) => (index: number, cardData: BoardCard) => {
    const { column, id } = cardData;
    return (
        <KanbandBoardCard
            key={`kanban-board-card__${column}_${id}`}
            component={cardComponent}
            index={index}
            {...cardData}
        />
    );
};

interface KanbanBoardExpandedColumnProps extends BaseProps {
    name: string;
    title?: string;
    column: BoardColumn;
    cards?: BoardCard[];
}

const KanbanBoardExpandedColumn = ({
    name,
    title,
    column,
    cards = [],
    cardComponent,
    columnHeaderComponent: HeaderComponent = ColumnTitle,
    columnFooterComponent: FooterComponent,
    onAddCardClick = () => {},
    onColumnCollapse = () => {},
}: KanbanBoardExpandedColumnProps) => {
    const footer = useMemo(
        () => FooterComponent && (
            <ColumnFooter>
                <FooterComponent cards={cards} />
            </ColumnFooter>
        ),
        [FooterComponent, cards],
    );

    return (
        <ColumnContainer>
            <ColumnHeader>
                <HeaderComponent
                    title={title}
                    column={column}
                    cards={cards}
                />
                <ColumnHeaderActions>
                    <StyledButtonIcon
                        variant="base"
                        borderRadius="semi-square"
                        size="small"
                        icon={<Plus />}
                        onClick={() => onAddCardClick(name)}
                        tooltip="New Opportunity"
                    />
                    <StyledButtonIcon
                        variant="base"
                        borderRadius="semi-square"
                        size="small"
                        icon={<SidebarShrinkIcon />}
                        onClick={() => onColumnCollapse(column)}
                        tooltip="Collapse Column"
                    />
                </ColumnHeaderActions>
            </ColumnHeader>
            <ColumnContent>
                <Droppable
                    droppableId={name}
                    mode="virtual"
                    renderClone={(provided, snapshot, rubric) => {
                        const Component = cardComponent || DefaultCard;
                        return (
                            <CardContainer
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                isDragging={snapshot.isDragging}
                            >
                                <Component {...cards[rubric.source.index]} />
                            </CardContainer>
                        );
                    }}
                >
                    {(provided, snapshot) => (
                        <ColumnCards
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            isDraggingOver={snapshot.isDraggingOver}
                        >
                            <StyledVirtuoso
                                data={cards}
                                initialTopMostItemIndex={0}
                                scrollerRef={provided.innerRef as any}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                itemContent={getVirtuosoItemContent(cardComponent)}
                            />
                        </ColumnCards>
                    )}
                </Droppable>
                <StyledGradient position="bottom" />
            </ColumnContent>
            {footer}
        </ColumnContainer>
    );
};

export default KanbanBoardExpandedColumn;
