import { useEffect, useMemo, useState } from 'react';
import {
    ref, getMetadata, getDownloadURL,
} from 'firebase/storage';
import { RenderIf } from 'react-rainbow-components';
import Note from 'components/icons/note';
import fileSizeFormatter from 'data/services/size/formatter';
import trucateFilename from 'data/services/string/trucateFilename';
import { storage } from '../../firebase';
import {
    FileContainer, Label, SizeLabel, StyledZoomableImage,
    FileTypeIconContainer, ImageContainer, StyledLoadingShape,
} from './styled';
import { FileData } from './types';
import DownloadButton from './download';

interface NoteAttachedFileProps {
    fileId?: string;
}

const NoteAttachedFile = ({
    fileId,
}: NoteAttachedFileProps) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<FileData>();
    const [downloadButtonVisible, setDownloadButtonVisible] = useState<boolean>(false);

    const formattedFilename = useMemo(
        () => trucateFilename(data?.name),
        [data],
    );

    const formattedFileSize = useMemo(
        () => fileSizeFormatter(data?.size || 0),
        [data?.size],
    );

    const isImage = useMemo(() => /image/gi.test(data?.contentType || ''), [data]);

    useEffect(
        () => {
            (async () => {
                setLoading(true);
                try {
                    const fileRef = ref(storage, fileId);
                    const metadata = await getMetadata(fileRef);
                    const url = await getDownloadURL(fileRef);
                    setData({ ...metadata, url });
                } catch (error) {
                    // no catch
                }
                setLoading(false);
            })();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    if (isLoading) {
        return (
            <FileContainer>
                <StyledLoadingShape />
            </FileContainer>
        );
    }

    return (
        <FileContainer
            onMouseEnter={() => setDownloadButtonVisible(true)}
            onMouseLeave={() => setDownloadButtonVisible(false)}
        >
            <ImageContainer>
                <RenderIf isTrue={isImage}>
                    <StyledZoomableImage
                        src={data?.url || ''}
                        alt={data?.name}
                        width={80}
                        height={80}
                    />
                </RenderIf>
                <RenderIf isTrue={!isImage}>
                    <FileTypeIconContainer>
                        <Note />
                    </FileTypeIconContainer>
                </RenderIf>
            </ImageContainer>
            <RenderIf isTrue={downloadButtonVisible}>
                <DownloadButton file={data} />
            </RenderIf>
            <Label>{formattedFilename}</Label>
            <SizeLabel>{formattedFileSize}</SizeLabel>
        </FileContainer>
    );
};

export default NoteAttachedFile;
