import { IconProps } from 'types';

const Pencil = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
    >
        <title>{title}</title>
        <path d="M14.625 20.625C14.625 21.0392 14.9608 21.375 15.375 21.375C15.7892 21.375 16.125 21.0392 16.125 20.625C16.125 20.2108 15.7892 19.875 15.375 19.875C14.9608 19.875 14.625 20.2108 14.625 20.625Z" fill="currentColor" />
        <path d="M18.4137 15.3065C18.5513 15.028 18.9485 15.028 19.0861 15.3065L19.2723 15.6831C19.5881 16.3223 20.0456 16.881 20.6099 17.3167L20.9906 17.6106C21.185 17.7607 21.185 18.0541 20.9906 18.2042L20.6099 18.4982C20.0456 18.9338 19.5881 19.4926 19.2723 20.1317L19.0861 20.5084C18.9485 20.7869 18.5513 20.7869 18.4137 20.5084L18.2276 20.1317C17.9118 19.4926 17.4543 18.9338 16.89 18.4982L16.5093 18.2042C16.3149 18.0541 16.3149 17.7607 16.5093 17.6106L16.89 17.3167C17.4543 16.881 17.9118 16.3223 18.2276 15.6831L18.4137 15.3065Z" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
        <path d="M20.0124 6.597C20.7317 5.87779 20.7317 4.71173 20.0124 3.99253C19.2934 3.27332 18.1273 3.27332 17.4081 3.99253L5.61599 15.7846C5.28045 16.1201 5.11268 16.2879 4.99274 16.4897C4.87277 16.6915 4.80557 16.9191 4.67119 17.3742L4.04517 19.494C3.95905 19.7856 3.91598 19.9315 3.99479 20.0102C4.07359 20.0891 4.21939 20.046 4.51101 19.96L6.63089 19.3339C7.08599 19.1995 7.31354 19.1323 7.51534 19.0123C7.71715 18.8923 7.88492 18.7246 8.22045 18.3891L20.0124 6.597Z" stroke="currentColor" strokeWidth="2" />
    </svg>
);
export default Pencil;
