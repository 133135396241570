import { useParams } from 'react-router-dom';
import { orderBy, query } from 'firebase/firestore';
import { TimelineMarker } from 'react-rainbow-components';
import LoadingShape from 'components/LoadingShape';
import useCustomerMemory from 'data/firestore/agent/customer/memory/useCollection';
import useRedirectWhenNotAdmin from 'hooks/useRedirectWhenNotAdmin';
import { CardContent, Container, TimeLine } from './styled';
import MemoriesList from './memoriesList';

const CustomerDetailsMemory = () => {
    const { agentId = '', customerId = '' } = useParams();
    useRedirectWhenNotAdmin({ redirectTo: '..' });

    const { data = [], isLoading } = useCustomerMemory(agentId, customerId, {
        disabled: !agentId || !customerId,
        listQueryFn: (ref) => query(ref, orderBy('timeMark', 'desc'), orderBy('createdAt', 'desc')),
    });

    if (isLoading) {
        return (
            <CardContent>
                <Container>
                    <TimeLine>
                        <TimelineMarker
                            icon={<LoadingShape variant="solid" shape="circle" width="48px" height="48px" />}
                            label={<LoadingShape variant="solid" shape="rounded-rect" width="200px" height="14px" />}
                            description={<LoadingShape variant="solid" shape="rounded-rect" width="300px" height="10px" />}
                        >
                            <LoadingShape variant="solid" shape="rounded-rect" width="100%" height="100px" />
                        </TimelineMarker>
                    </TimeLine>
                </Container>
            </CardContent>
        );
    }

    return (
        <CardContent>
            <MemoriesList data={data} />
        </CardContent>
    );
};

export default CustomerDetailsMemory;
