import { useRef, useState } from 'react';
import Checks from 'components/icons/checks';
import FrequentMessage from 'components/FrequentMessage';
import {
    ModalContainer,
    ModalDescription,
    ModalIconContainer,
    ModalTitle,
    ModalBody,
    NoteInput,
    BoxContainer,
    InputContainer,
    ActionsContainer,
} from '../styled';

interface Params {
    onNoteChanged?: (value: string) => void;
    agentId: string;
}

const ResolveModal = ({
    onNoteChanged = () => {},
    agentId,
}:Params) => {
    const [value, setValue] = useState('');
    const inputRef = useRef<HTMLTextAreaElement>();

    const handleNoteChanged = (inputValue: string) => {
        setValue(inputValue);
        onNoteChanged(inputValue);
    };

    const setInputValue = (text: string) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line max-len
        const textarea = inputRef.current.fieldRef.current.textareaRef.current;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLTextAreaElement.prototype,
            'value',
        ).set;
        nativeInputValueSetter?.call(textarea, text);
        const event = new Event('input', { bubbles: true });
        textarea.dispatchEvent(event);
        setTimeout(() => inputRef.current?.focus(), 0);
    };

    return (
        <ModalContainer>
            <ModalIconContainer>
                <Checks />
            </ModalIconContainer>
            <ModalTitle>Ready to Mark as Solved?</ModalTitle>
            <ModalBody>
                <ModalDescription>
                    Once you confirm, this task will be marked as complete.
                    Any last thoughts? Jot them down in the note area.
                </ModalDescription>
                {/* <NoteInput
                    label="Leave a Note (Optional)"
                    labelAlignment="left"
                    placeholder="Add your notes here..."
                    rows={1}
                    grow
                    onChange={(event) => onNoteChanged(event.target.value)}
                /> */}
                <BoxContainer>
                    <InputContainer>
                        <NoteInput
                            variant="shaded"
                            labelAlignment="left"
                            value={value}
                            placeholder="Add your notes here..."
                            rows={1}
                            grow
                            onChange={(event) => handleNoteChanged(event.target.value)}
                            ref={inputRef}
                        />
                        <ActionsContainer>
                            {/* <ActionButton
                                borderRadius="semi-square"
                                title="Attach image"
                                icon={<ImageIcon />}
                                disabled
                            />
                            <ActionButton
                                borderRadius="semi-square"
                                title="Record Audio"
                                icon={<MicrophoneIcon />}
                                disabled
                            /> */}
                            <FrequentMessage
                                context="notes"
                                onSelectMessage={setInputValue}
                                agentId={agentId}
                            />
                        </ActionsContainer>
                    </InputContainer>
                </BoxContainer>
            </ModalBody>
        </ModalContainer>
    );
};

export default ResolveModal;
