import { Frequentmessage } from 'data/firestore/agent/frequentmessage/types';
import { EntityGet } from 'data/firestore/types';
import Option from './option';

interface MessagesProps {
    data?: EntityGet<Frequentmessage>[]
    onMessageClick?: (description: string) => void
}
const Messages = ({
    data = [],
    onMessageClick = () => { },
}: MessagesProps) => (
    <>
        {data.map(({ content, title, id }) => (
            <Option
                key={id}
                name={title}
                description={content}
                onClick={onMessageClick}
            />
        ))}
    </>
);

export default Messages;
