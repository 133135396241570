import {
    useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { VirtuosoHandle } from 'react-virtuoso';
import { AggregatedDeliveryReceipt, Message, Paginator } from '@twilio/conversations';
import { RenderIf } from 'react-rainbow-components';
import LoadingShape from 'components/LoadingShape';
import { EntityGet } from 'data/firestore/types';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { Customer } from 'data/firestore/agent/customer/types';
import sound from './assets/notification.mp3';
import SMSChatMessages from './ChatMessages';
import useConversation from './hooks/useConversation';
import useMessagesCount from './hooks/useMessageCount';
import useMessages from './hooks/useMessages';
import getMessageStatus from './ChatMessages/helpers/getMessageStatus';
import { LoadingContainer } from './styled';
import SMSChatLayout from './layout';
import getMediaOptions from './helpers/getMediaOptions';

const playNotificationSound = () => {
    const audio = new Audio(sound);
    audio.play();
};

interface SMSChatProps {
    customerData: EntityGet<Customer>;
    onRequestClose?: () => void;
    onBackClick?: () => void;
}

const SMSChat = ({
    customerData,
    onRequestClose = () => {},
    onBackClick = () => {},
}: SMSChatProps) => {
    const { agentId = '' } = useParams();
    const virtuosoRef = useRef<VirtuosoHandle>(null);
    const inputRef = useRef();
    const customerId = useMemo(() => (customerData as unknown as Customer)?.id, [customerData]);
    const queryClient = useQueryClient();

    const {
        mutateAsync: removeCustomerNotifications,
    } = useHttpMutation<Record<string, unknown>, void>({
        pathname: `/agents/${agentId}/conversations/mark-read`,
        method: 'POST',
    });

    const {
        data: { conversation, participants } = {},
        isLoading: isLoadingConversation,
    } = useConversation(customerId);

    const {
        data: messagesCount = 0, isLoading: isLoadingMessagesCount,
    } = useMessagesCount(customerId, conversation);

    const {
        key: messagesQueryKey,
        data: messages,
        isLoading: isLoadingMessages,
        refetch: refetchMessages,
    } = useMessages(customerId, conversation);

    const isLoading = useMemo(
        () => isLoadingConversation || isLoadingMessagesCount || isLoadingMessages,
        [isLoadingConversation, isLoadingMessages, isLoadingMessagesCount],
    );

    useEffect(
        () => {
            if (conversation) {
                conversation.on('messageAdded', (newMessage) => {
                    if (!newMessage?.author?.startsWith('+')) {
                        newMessage.addListenerWithReplay('updated', (newMsg) => {
                            if (newMsg.updateReasons[0] === 'deliveryReceipt') {
                                refetchMessages();
                                const messageStatus = getMessageStatus(
                                    newMsg?.message
                                        .aggregatedDeliveryReceipt as AggregatedDeliveryReceipt,
                                );
                                if (
                                    messageStatus === 'delivered'
                                    || messageStatus === 'read'
                                    || messageStatus === 'undelivered'
                                ) {
                                    newMessage.removeAllListeners();
                                }
                            }
                        });
                    }
                    queryClient.setQueryData<Paginator<Message> | undefined>(
                        messagesQueryKey,
                        (oldData) => {
                            if (oldData) {
                                return {
                                    ...oldData,
                                    items: [...oldData.items, newMessage],
                                };
                            }
                            return oldData;
                        },
                    );
                    if (newMessage?.author?.startsWith('+')) {
                        playNotificationSound();
                    }
                });
            }
            return () => {
                if (conversation) {
                    conversation.removeAllListeners();
                }
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [customerId, conversation, queryClient],
    );

    // return focus to input after send a message
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inputRef?.current?.focus();
    }, [messages?.items.length]);

    const submit = useCallback(
        async (value: string, attachedFiles: File[] = []) => {
            if (attachedFiles.length > 0) {
                await Promise.allSettled(
                    attachedFiles.map((file) => conversation?.sendMessage(getMediaOptions(file))),
                );
            }
            if (value) {
                await conversation?.sendMessage(value);
            }
            if (messages?.items) {
                virtuosoRef?.current?.scrollToIndex({
                    index: messages.items.length,
                    behavior: 'smooth',
                });
            }
        },
        [conversation, messages?.items],
    );

    const handleClearUnreadCustomerNotifications = useCallback(
        async () => {
            try {
                removeCustomerNotifications({
                    body: {
                        customerId: customerData.id,
                    },
                });
            } catch (error) {
                // no catch
            }
        },
        [customerData.id, removeCustomerNotifications],
    );

    const customerInfo = (participants || {})[customerData.phoneNumber as string] || customerData;

    return (
        <SMSChatLayout
            customerData={customerInfo}
            onRequestClose={onRequestClose}
            inputRef={inputRef}
            onTextSend={submit}
            onBackClick={onBackClick}
        >
            <RenderIf isTrue={isLoading}>
                <LoadingContainer>
                    <LoadingShape width="90%" />
                    <LoadingShape width="40%" />
                    <LoadingShape width="60%" />
                </LoadingContainer>
            </RenderIf>
            <RenderIf isTrue={!isLoading}>
                <SMSChatMessages
                    ref={virtuosoRef}
                    agentId={agentId}
                    customerId={customerId}
                    participants={participants}
                    messagesCount={messagesCount}
                    messages={messages}
                    onReadMessages={handleClearUnreadCustomerNotifications}
                />
            </RenderIf>
        </SMSChatLayout>
    );
};

export default SMSChat;
