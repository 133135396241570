import { BarDatum, BarTooltipProps } from '@nivo/bar';
import { TooltipContainer } from './styled';

const BarTooltip = ({ data, value }: BarTooltipProps<BarDatum>) => (
    <TooltipContainer>
        <h3>{`${data.date}: ${value}`}</h3>
        <p>Click to see details</p>
    </TooltipContainer>
);

export default BarTooltip;
