import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.h3`
    display: flex;
    padding: 0 0.25rem 0.25rem 0.25rem;
    align-items: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.625rem;
`;

export const ArrowsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    height: 3rem;
    padding: 0 0.25rem;
`;
