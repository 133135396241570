import styled, { createGlobalStyle, css } from 'styled-components';
import { BACKGROUND } from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    background-color: ${BACKGROUND};
`;

export const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    padding: 0 1.5rem 0 1.5rem;
`;

export const OutletContainer = styled.div`
    margin-top: 24px;
    height: 100%;
`;

export const Footer = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 0 0.5rem 0;
    line-height: 1.467;
    font-size: 0.85rem;
    color: #6f6d7d;
    font-weight: 400;
`;

export const TopBarContainer = styled.div`
    margin-bottom: 0;
    sticky: top;
    position: sticky;
    top: 0px;
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    z-index: 3;
    background: transparent;
    backdrop-filter: blur(10px);
    margin-left: -24px;
    margin-right: -24px;
`;

export const ModalStyle = createGlobalStyle<{ smsChatOpen?: boolean; customerPanelOpen?: boolean }>`
    body > div[role="presentation"] {
        ${(props) => props.smsChatOpen && css`
            width: calc(100% - 408px);
        `};

        ${(props) => props.customerPanelOpen && css`
            width: calc(100% - 408px);
        `};

        ${(props) => props.smsChatOpen && props.customerPanelOpen && css`
            width: calc(100% - 810px);
        `};
    }
`;
