import Close from 'components/icons/close';
import {
    CloseButton, HeaderContainer, HeaderSubtitle, HeaderTitle,
} from './styled';

const DrawerHeader = ({
    title,
    subtitle,
    onRequestClose = () => {},
}: {
    title: string;
    subtitle: string;
    onRequestClose?: () => void;
}) => (
    <HeaderContainer>
        <HeaderTitle>{title}</HeaderTitle>
        <HeaderSubtitle>{subtitle}</HeaderSubtitle>
        <CloseButton icon={<Close />} onClick={onRequestClose} />
    </HeaderContainer>
);

export default DrawerHeader;
