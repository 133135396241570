import {
    useCallback, useMemo, useRef, useState,
} from 'react';
import { useOutsideClick, useWindowResize } from '@rainbow-modules/hooks';
import Filter from 'components/icons/filter';
import { Provider } from 'react-rainbow-components/components/PrimitiveMenu/context';
import ButtonIcon from 'components/ButtonIcon';
import Check from 'components/icons/check';
import Close from 'components/icons/close';
import { BadgeOverlay, InternalOverlay } from 'react-rainbow-components';
import { BaseProps } from '../../../../types';
import positionResolver from './resolver';
import {
    DropDown, StyledMenuItem, Header,
} from './styled';

const filterOptions = [
    { label: 'Show All Conversations', name: 'all' },
    { label: 'Show Unread Only', name: 'unread' },
];

interface UnreadFilterPickerProps extends BaseProps {
    value?: string;
    onChange?: (newValue: string) => void;
}

const UnreadFilterPicker = ({
    value: valueInProps,
    onChange = () => {},
    ...rest
}: UnreadFilterPickerProps) => {
    const buttonRef = useRef();
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isOpen, setOpen] = useState(false);

    useWindowResize(() => setOpen(false), isOpen);
    useOutsideClick(dropdownRef, () => setOpen(false), isOpen);

    const toggle = useCallback(() => setOpen(!isOpen), [isOpen]);

    const close = useCallback(() => setOpen(false), []);

    const changeStatus = useCallback(
        (newStatus: string) => onChange(newStatus),
        [onChange],
    );

    const options = useMemo(
        () => filterOptions.map(
            (option) => {
                const icon = (
                    valueInProps === option.name
                        ? <Check />
                        : undefined
                );
                return (
                    <StyledMenuItem
                        key={option.name}
                        icon={icon}
                        iconPosition="right"
                        label={option.label || option.name}
                        onClick={() => changeStatus(option.name)}
                    />
                );
            },
        ),
        [changeStatus, valueInProps],
    );

    const contextValue = useMemo(() => ({
        privateOnClose: () => {},
        privateOnHover: (event: any, childRef: any) => childRef.focus(),
        privateRegisterChild: () => {},
        privateUnregisterChild: () => {},
    }), []);

    return (
        <>
            <ButtonIcon
                {...rest}
                ref={buttonRef}
                variant="base"
                onClick={toggle}
                icon={(
                    <BadgeOverlay
                        position="top-left"
                        isHidden={valueInProps === 'all'}
                    >
                        <Filter />
                    </BadgeOverlay>
                )}
            />
            <InternalOverlay
                isVisible={isOpen}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                triggerElementRef={() => buttonRef.current.htmlElementRef}
                positionResolver={positionResolver}
            >
                <DropDown ref={dropdownRef}>
                    <Provider value={contextValue}>
                        <Header>
                            Show Tasks
                            <ButtonIcon
                                icon={<Close />}
                                onClick={close}
                            />
                        </Header>
                        {options}
                    </Provider>
                </DropDown>
            </InternalOverlay>
        </>
    );
};

export default UnreadFilterPicker;
