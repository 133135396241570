import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import Close from 'components/icons/close';
import SearchIcon from 'components/icons/search';
import useAlgoliaSearchData from 'data/algolia/useSearchData';
import { EntityGet } from 'data/firestore/types';
import { Customer } from 'data/firestore/agent/customer/types';
import useDebouncedState from 'hooks/useDebouncedState';
import { ALGOLIA_INDEX_CUSTOMERS, CHAT_PAGE_SIZE } from '../../../constants';
import CustomerMessages from './messages';
import {
    CloseButton, Container, Content, Header, SearchInput, Title, TitleContainer, FilterButton,
} from '../styled';

const buildAlgoliaFilters = ({
    agentId,
    search,
    showUnreadOnly = false,
}: {
    agentId: string,
    search: string,
    showUnreadOnly?: boolean
}) => {
    const filters = [`agentId:${agentId}`];
    if (!search) {
        filters.push('hasConversation:true');
        if (showUnreadOnly) {
            filters.push('hasNewMessage:true');
        }
    }
    return filters.join(' AND ');
};

interface ConversationsListProps {
    onRequestClose?: () => void;
    onCustomerSelect?: ((customer: EntityGet<Customer>) => void);
}

const ConversationsList = ({
    onRequestClose = () => {},
    onCustomerSelect,
}: ConversationsListProps) => {
    const { agentId = '' } = useParams();
    const [data, setData] = useState<EntityGet<Customer>[]>([]);
    const [showMessages, setShowMessages] = useState<string>('all');
    const [search, setSearch] = useDebouncedState<string>('', 500);
    const [activePage, setActivePage] = useState<number>(1);

    const algoliaData = useAlgoliaSearchData<EntityGet<Customer>>({
        search,
        activePage,
        pageSize: CHAT_PAGE_SIZE,
        indexName: ALGOLIA_INDEX_CUSTOMERS,
        filters: buildAlgoliaFilters({
            agentId,
            search,
            showUnreadOnly: showMessages === 'unread',
        }),
        enabled: true,
    });

    const searchCustomers = useCallback(
        (value: string) => {
            if (activePage > 1) setActivePage(1);
            setSearch(value);
        },
        [activePage, setSearch],
    );

    const handleLoadMore = useCallback(
        (atBottom:boolean) => {
            const totalPages = algoliaData?.nbPages || 0;
            if (atBottom && totalPages > 1 && activePage < totalPages) {
                setActivePage(activePage + 1);
            }
        },
        [activePage, algoliaData?.nbPages],
    );

    useEffect(
        () => {
            if (!algoliaData.isLoading && algoliaData?.hits) {
                setData((
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    algoliaData?.page > 0
                        ? [...data, ...(algoliaData?.hits || [] as EntityGet<Customer>[])]
                        : (algoliaData?.hits || [] as EntityGet<Customer>[])
                ));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [algoliaData.isLoading],
    );

    const isLoading = useMemo(
        () => algoliaData.isLoading && activePage === 1,
        [activePage, algoliaData.isLoading],
    );

    return (
        <Container>
            <Header>
                <TitleContainer>
                    <Title className="rainbow-m-left_x-small">Chats</Title>
                    <SearchInput
                        icon={<SearchIcon />}
                        placeholder="Type something"
                        onChange={(e) => searchCustomers(e.target.value)}
                    />
                </TitleContainer>
                <FilterButton value={showMessages} onChange={setShowMessages} />
                <CloseButton icon={<Close />} onClick={onRequestClose} />
            </Header>
            <Content>
                <CustomerMessages
                    data={data}
                    isLoading={isLoading}
                    onClick={onCustomerSelect}
                    onLoadMore={handleLoadMore}
                />
            </Content>
        </Container>
    );
};

export default ConversationsList;
