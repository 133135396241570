/* eslint-disable react/no-unused-prop-types */
import {
    ReactNode, useCallback, useMemo,
} from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { Option } from 'react-rainbow-components';
import { StyledPicklist } from 'components/Instructions/aiModelConfigForm/styled';

interface GPT4ModelPickerProps {
    id?: string;
    className?: string;
    name?: string;
    label?: string;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    value?: string;
    onChange?: (value: string) => void;
}

const models = [
    'gpt-4-turbo',
    'gpt-4-turbo-2024-04-09',
    'gpt-4o-2024-05-13',
    'gpt-4o-2024-08-06',
    'gpt-4o-mini-2024-07-18',
];

const GPT4ModelPicker = (props: GPT4ModelPickerProps) => {
    const {
        value: valueInProps,
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex,
        placeholder,
        error,
        required,
    } = useReduxForm(props);

    const pickListvalue = useMemo(() => {
        if (!valueInProps) return undefined;
        const modelFound = models.find((model) => model === valueInProps);
        return {
            name: modelFound,
            label: modelFound,
            value: modelFound,
        };
    }, [valueInProps]);

    const handleOnChange = useCallback(
        (value: PicklistValue) => onChange(value.value),
        [onChange],
    );

    const pickListOptions = useMemo(
        () => models.map(
            (model) => (
                <Option
                    key={`gpt4-picker-option__${model}`}
                    name={model}
                    label={model}
                    value={model}
                />
            ),
        ),
        [],
    );

    return (
        <StyledPicklist
            id={id}
            name={name}
            className={className}
            label={label}
            labelAlignment="left"
            tabIndex={tabIndex}
            required={required}
            placeholder={placeholder}
            value={pickListvalue}
            onChange={handleOnChange}
            error={error}
        >
            {pickListOptions}
        </StyledPicklist>
    );
};

export default GPT4ModelPicker;
