import { useContext } from 'react';
import { RenderIf } from 'react-rainbow-components';
import SidebarExpandIcon from 'components/icons/sidebarExpand';
import Trash from 'components/icons/trash';
import Pencil from 'components/icons/pencil3';
import { BaseProps, BoardCard, BoardColumn } from '../types';
import ColumnTitle from './columnTitle';
import KanbanBoardCollapsedColumn from './collapsedColumn';
import KanbanBoardExpandedColumn from './expandedColumn';
import { ActionsContainer, StyledButtonIcon, ExpandButtonIcon } from './styled';
import context, { Context } from '../context';

interface KanbanBoardColumnProps extends BaseProps {
    name: string;
    title?: string;
    column: BoardColumn;
    cards?: BoardCard[];
    index: number
}

const KanbanBoardColumn = ({
    title,
    column,
    cards = [],
    index,
    columnHeaderComponent: HeaderComponent = ColumnTitle,
    onColumnExpand = () => {},
    onRequestColumnEdit = () => {},
    onRequestColumnDelete = () => {},
    ...otherProps
}: KanbanBoardColumnProps) => {
    const { columnEditMode } = useContext<Context>(context);
    if (columnEditMode) {
        return (
            <KanbanBoardCollapsedColumn
                index={index}
                title={title}
                column={column}
                cards={cards}
                columnHeaderComponent={HeaderComponent}
                disabled={!column.draggable}
                actions={(
                    <RenderIf isTrue={column.editable || column.removable}>
                        <ActionsContainer>
                            <StyledButtonIcon
                                variant="base"
                                borderRadius="semi-square"
                                size="small"
                                icon={<Pencil />}
                                disabled={!column.editable}
                                onClick={() => onRequestColumnEdit(column)}
                                tooltip="Edit Column"
                            />
                            <StyledButtonIcon
                                variant="base"
                                borderRadius="semi-square"
                                size="small"
                                icon={<Trash />}
                                disabled={!column.removable}
                                onClick={() => onRequestColumnDelete(column)}
                                tooltip="Remove Column"
                            />
                        </ActionsContainer>
                    </RenderIf>
                )}
            />
        );
    }

    if (column.collapsed) {
        return (
            <KanbanBoardCollapsedColumn
                index={index}
                title={title}
                column={column}
                cards={cards}
                columnHeaderComponent={HeaderComponent}
                onColumnExpand={onColumnExpand}
                tooltip="Expand Column"
                actions={(
                    <ExpandButtonIcon
                        variant="base"
                        borderRadius="semi-square"
                        size="small"
                        icon={<SidebarExpandIcon />}
                        onClick={() => onColumnExpand(column)}
                    />
                )}
            />
        );
    }

    return (
        <KanbanBoardExpandedColumn
            title={title}
            column={column}
            cards={cards}
            columnHeaderComponent={HeaderComponent}
            {...otherProps}
        />
    );
};

export default KanbanBoardColumn;
