import { IconProps } from 'types';

const Clock = ({ title = 'File Text', className }: IconProps) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <title>{title}</title>
        <path d="M17 5H19C20.1046 5 21 5.89543 21 7V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V7C3 5.89543 3.89543 5 5 5H7" stroke="currentColor" strokeWidth="2" />
        <path d="M8 12H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <path d="M8 16H13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <path d="M15 2.72727H14.3159C14.0184 2.2884 13.5156 2 12.9455 2H11.0545C10.4844 2 9.98158 2.2884 9.68406 2.72727H9C7.89543 2.72727 7 3.6227 7 4.72727V6C7 7.10457 7.89543 8 9 8H15C16.1046 8 17 7.10457 17 6V4.72727C17 3.6227 16.1046 2.72727 15 2.72727Z" stroke="currentColor" strokeWidth="2" strokeLinejoin="bevel" />
    </svg>
);

export default Clock;
