import styled, { createGlobalStyle, css } from 'styled-components';
import { Drawer } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import Input from 'components/Input';
import { color } from 'theme';
import { replaceAlpha } from 'react-rainbow-components/styles/helpers/color';
import EmptyMessage from 'components/EmptyMessage';
import {
    WHITE, GREY_700, GREY_800, GREY_200, GREY_50, PURPLE_100, PURPLE_800,
} from '../../constants';

const getWidth = (value: string | number = 400) => {
    if (typeof value === 'string') return value;
    return `${value}px`;
};

export const ActionButton = styled(ButtonIcon)`
    width: 3rem;
    height: 3rem;

    svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
        font-size: 1.5rem !important;
    }
`;

export const SearchInput = styled(Input)`
    width: 100%;

    span {
        left: 1rem;
    }

    svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
        font-size: 1.5rem !important;
    }

    input {
        background: ${GREY_50};
        border-color: ${GREY_50};
        height: 3rem;
        line-height: 3rem;
        padding: 0px 1rem 0px 3rem;

        &:focus {
            padding-left: 2.9375rem;
        }
    }
`;

export const Dropdown = styled.div`
    width: 25rem;
    padding: 1rem 1.25rem 0 1.25rem;
    flex-direction: column;
    align-items: flex-start;
    background: ${WHITE};
    border-radius: 1.5rem;
    box-shadow: 0px  4px 16px 0px rgba(75, 70, 92, 0.10);
`;

export const DropdownContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DropdownHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
`;

export const DropdownTitle = styled.h3`
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.625rem;
    color: ${GREY_700};
    flex: 1;
`;

export const StyledDrawer = styled(Drawer)<{ width: string | number }>`
    border-radius: 1.5rem;
    margin: 1.5rem;
    box-sizing: border-box;
    height: calc(100% - 3rem);
    min-height: calc(100% - 3rem);
    max-height: calc(100% - 3rem);
    box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.07);

    & > h1 + div {
        margin: 0;
    }    

    width: ${(props) => getWidth(props.width)};

    div[id^=drawer-content] {
        padding: 0;
    }
    > div {
        padding-bottom: 0;
        box-sizing: border-box;
    }
`;

export const ModalStyle = createGlobalStyle<{ isSettingsModalOpen?: boolean; isCreateModalOpen?: boolean }>`
    ${(props) => props.isSettingsModalOpen && css`
        div[role=presentation] {
            z-index: 5000;
            background: none;
            backdrop-filter: unset;
        }
    `};

    body > div#frequent-message-settings-drawer {
        width: 100%;
        background: ${((props) => replaceAlpha(color('background.highlight')(props), 0.64))};
        backdrop-filter: blur(5px);

        ${(props) => props.isSettingsModalOpen && props.isCreateModalOpen && css`
            width: calc(100% - 560px);
        `};
    }
    
    body > div#frequent-message-create-edit-drawer {
        width: 100%;
        background: ${((props) => replaceAlpha(color('background.highlight')(props), 0.64))};

        ${(props) => props.isSettingsModalOpen && css`
            width: 560px;
            left: calc(100% - 560px);
        `}
    }
`;

export const CloseButton = styled(ButtonIcon)`
    position: absolute;
    right: 1rem;
    top: 1rem;
`;

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    overflow: hidden;
    gap: 1rem;
    padding: 2rem;
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
`;

export const HeaderTitle = styled.span`
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
    color: #1E1E1E;
`;

export const HeaderSubtitle = styled.span`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    color: #88888D;
`;

export const ListContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 25rem;
    overflow: hidden;
`;

export const StyledUl = styled.ul`
    margin: 0;
    padding: 1.25rem 0;
    list-style: none;
    box-sizing: border-box;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    height: 100%;
        
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }   
`;

export const StyledLi = styled.li`
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    list-style: none;
    box-sizing: border-box;
    width: 100%;
`;

export const IconContainer = styled.div`
    padding: 0.75rem;
    flex-shrink: 0;
`;

export const StyledOption = styled.div`
    display: flex;
    align-items: center;
    padding: 0.5rem 0 0.5rem 1rem;
    border-radius: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    color: ${GREY_800};
    gap: 0.75rem;
    width: 100%;

    &:hover {
        background-color: ${PURPLE_100};
        color: ${PURPLE_800};
    }
`;

export const LeftContent = styled.div`
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const OptionName = styled.h4`
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.75rem;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const OptionDescription = styled.p`
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const TopGradient = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2rem;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, ${WHITE} 50%);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: ${GREY_200};
`;

export const BottomGradient = styled.div`
    position: absolute;
    bottom: 0rem;
    left: 0;
    right: 0;
    height: 2rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${WHITE} 50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${GREY_200};
`;

export const StyledEmptyMessage = styled(EmptyMessage)`
    padding: 2rem 0 3rem 0;
`;
