import { useMemo } from 'react';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';

const usePipelineStageInsightsDetails = (drawerId: string) => {
    const { isOpen, data, ...connectedDrawerProps } = useConnectModal(
        drawerId,
        { size: 'small', slideFrom: 'right' },
    );
    const [open, close] = useOpenModal(drawerId);

    return useMemo(
        () => ({
            isOpen: isOpen as boolean,
            connectedDrawerProps,
            data: data as Record<string, unknown>,
            open,
            close,
        }),
        [close, isOpen, data, open, connectedDrawerProps],
    );
};

export default usePipelineStageInsightsDetails;
