import { DateTime } from 'luxon';
import { Type } from 'components/DateFilter/types';

const formatDate = ({
    dateStr,
    unit,
    timeZone = '',
}: {
    dateStr: string,
    unit: Type,
    timeZone?: string;
}) => {
    const date = DateTime.fromISO(dateStr, { zone: timeZone });

    if (unit === 'month') return date.toFormat('LLLL');

    return date.toFormat('LLL dd, yyyy');
};

export default formatDate;
