import { useMemo } from 'react';
import { Conversation, Message, Paginator } from '@twilio/conversations';
import { useQuery } from 'react-query';
import { CHAT_PAGE_SIZE } from '../../../constants';
import useTwilioQueryKey from './useTwilioQueryKey';

const useMessages = (
    customerId: string,
    conversation?: Conversation,
) => {
    const key = useTwilioQueryKey(['messages', customerId], conversation);
    const queryResult = useQuery<unknown, unknown, Paginator<Message>>(
        key,
        () => conversation?.getMessages(CHAT_PAGE_SIZE),
        {
            enabled: Boolean(conversation),
            cacheTime: 0,
        },
    );

    return useMemo(() => ({
        key,
        ...queryResult,
    }), [key, queryResult]);
};

export default useMessages;
