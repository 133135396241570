import styled from 'styled-components';
import InstructionsEditor from 'components/InstructionsEditor';
import UniversalForm from 'components/UniversalForm';

export const Container = styled.div`
    display: flex;
    margin: 0 -1.5rem;
    flex-direction: column;
    gap: 1.625rem;
`;

export const StyledForm = styled(UniversalForm)`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-items: start;
    padding: 0 1.5rem;
    gap: 0;

    & > label {
        margin-left: 0;
    }
`;

export const Row = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    padding: 0 1.5rem;
    gap: 1rem;
`;

export const InstructionsInput = styled(InstructionsEditor)`
    height: 265px;
    flex-grow: 1;
    border-radius: 0.75rem;
    border: 1px solid #DBDADE;
    padding-top: 0.5rem;
`;
