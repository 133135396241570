import { IconProps } from 'types';

const Diamonds = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
    >
        <title>{title}</title>
        <path d="M9.00642 4.66003C9.3529 3.9727 10.3342 3.9727 10.6807 4.66003L11.8956 7.07005C12.2177 7.70903 12.6818 8.26577 13.2524 8.69762L15.5122 10.4081C16.0078 10.7832 16.0078 11.528 15.5122 11.9031L13.2524 13.6136C12.6818 14.0454 12.2177 14.6022 11.8956 15.2411L10.6807 17.6512C10.3342 18.3385 9.3529 18.3385 9.00642 17.6512L7.79154 15.2411C7.46943 14.6022 7.00533 14.0454 6.43477 13.6136L4.17492 11.9031C3.67932 11.528 3.67932 10.7832 4.17492 10.4081L6.43477 8.69762C7.00533 8.26577 7.46943 7.70903 7.79154 7.07005L9.00642 4.66003ZM17.7369 15.9154C17.998 15.3869 18.7517 15.3869 19.0127 15.9154C19.2525 16.4005 19.5998 16.8246 20.0282 17.1553L20.1424 17.2435C20.5761 17.5784 20.5761 18.2328 20.1424 18.5677L20.0282 18.6559C19.5998 18.9865 19.2525 19.4107 19.0127 19.8959C18.7517 20.4243 17.998 20.4243 17.7369 19.8959C17.4971 19.4107 17.1499 18.9865 16.7215 18.6559L16.6073 18.5677C16.1736 18.2328 16.1736 17.5784 16.6073 17.2435L16.7215 17.1553C17.1499 16.8246 17.4971 16.4005 17.7369 15.9154Z" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
    </svg>
);
export default Diamonds;
