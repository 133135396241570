import { useCallback, useMemo, useState } from 'react';

const useAttachFiles = ({
    onFilesAttached = () => {},
}: {
    onFilesAttached?: () => void,
} = {}) => {
    const [attachedFiles, setAttachedFiles] = useState<File[]>([]);

    const attach = useCallback(
        (filesList: FileList | null) => {
            const files = filesList ?? [];
            if (files.length > 0) {
                setAttachedFiles([
                    ...attachedFiles,
                    ...Array.from(files as FileList),
                ]);
                onFilesAttached();
            }
        },
        [attachedFiles, onFilesAttached],
    );

    const remove = useCallback(
        (index: number) => setAttachedFiles([
            ...attachedFiles.slice(0, index),
            ...attachedFiles.slice(index + 1),
        ]),
        [attachedFiles],
    );

    return useMemo(
        () => ({
            files: attachedFiles,
            attach,
            remove,
            clear: () => setAttachedFiles([]),
        }),
        [attach, remove, attachedFiles],
    );
};

export default useAttachFiles;
