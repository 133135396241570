import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    html {
        background: #f8f7fa !important;
    }

    body {
        font-family: 'Public Sans';
        background-color: #f8f7fa;
    }

    section[role="dialog"][aria-describedby^="modal-content"] {
        padding: 3.5625rem 4.0625rem;
        border-radius: 1.5rem;

        div[id^="modal-content"] {
            padding: 0;

            div[class*="IconContainer"]:empty {
                margin: 0;
            }
        }

        & > button[data-id="button-icon-element"] {
            border-radius: 2rem;
            top: 1rem;
            right: 1rem;
        }
        
        & > header {
            padding: 0;
        }

        & > footer {
            border: none;
            padding: 1.5rem 0rem 0rem 1.5rem;
            & > div {
                display: flex;
                justify-content: flex-end;
                align-content: center;
                gap: 1rem;
            }
            button {
                border-radius: 0.75rem;
                margin: 0;
                line-height: 3rem;
                height: 3rem;
            }
        }
    }

    div:has(> div > div[role="tooltip"]) {
        z-index: 5005;
    }

    div[role="tooltip"] {
        border-radius: 0.5rem!important;
    }
`;

export default GlobalStyle;
