import { useParams } from 'react-router-dom';
import Footer from 'components/Footer';
import usePipelines from 'data/firestore/agent/pipeline/useCollection';
import { Provider } from './context';
import ConversionJourneyInsights from './ConversionJourneyInsights';
import PipelineStageInsights from './PipelineStageInsights';
import { Container } from './styled';
import { GREEN_600, ORANGE_400, PURPLE_400 } from '../../constants';

const OpportunityInsights = () => {
    const { agentId = '' } = useParams();
    const { data: pipelines, isLoading: isLoadingPipelines } = usePipelines(
        agentId,
        { disabled: !agentId },
    );

    return (
        <Provider
            value={{
                pipelines,
                isLoading: isLoadingPipelines,
            }}
        >
            <Container>
                <ConversionJourneyInsights />
                <PipelineStageInsights
                    stageName="prospect"
                    title="Prospects"
                    description="Track AI interactions with prospects on the social media, showing engagement over the selected time range."
                    colors={[ORANGE_400]}
                />
                <PipelineStageInsights
                    stageName="qualified"
                    title="Qualified"
                    description="Track AI engagement with qualified leads on the social media over the selected time range."
                    colors={[PURPLE_400]}
                />
                <PipelineStageInsights
                    stageName="closed-won"
                    title="Close Won"
                    description="Track AI interactions that led to successful conversions on the social media over the selected time range."
                    colors={[GREEN_600]}
                />
                <Footer />
            </Container>
        </Provider>
    );
};

export default OpportunityInsights;
