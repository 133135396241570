/* eslint-disable @typescript-eslint/no-empty-function */
import { Pipeline } from 'data/firestore/agent/pipeline/types';
import { EntityGet } from 'data/firestore/types';
import React from 'react';

export interface Context {
    pipelines: EntityGet<Pipeline>[];
    isLoading: boolean;
}

const context = React.createContext<Context>({
    pipelines: [],
    isLoading: false,
});

export const { Provider, Consumer } = context;

export default context;
