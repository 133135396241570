import useResolveCustomers from 'data/hooks/useResolveCustomers';
import useCustomerPanel from 'hooks/useCustomerPanel';
import LoadingShape from 'components/LoadingShape';
import CustomerInfo from './customer';
import {
    CustomerInfoContainer, CustomerInfoContent, CustomerName, CustomerPhone, CustomersContainer,
} from './styled';

interface CustomersListProps {
    customers?: string[];
}
const CustomersList = ({ customers: customersIds = [] }: CustomersListProps) => {
    const { openCustomerPanel } = useCustomerPanel();

    const { isLoading, customers } = useResolveCustomers(customersIds);

    if (isLoading) {
        return (
            <CustomersContainer>
                <CustomerInfoContainer disabled>
                    <LoadingShape shape="circle" width="3rem" height="3rem" />
                    <CustomerInfoContent>
                        <CustomerName>
                            <LoadingShape width="100%" height="1rem" />
                        </CustomerName>
                        <CustomerPhone>
                            <LoadingShape width="100%" height="0.75rem" />
                        </CustomerPhone>
                    </CustomerInfoContent>
                </CustomerInfoContainer>
            </CustomersContainer>
        );
    }
    return (
        <CustomersContainer>
            {customers.map((customer, index) => {
                const key = `${customer.id}__${index}`;
                return (
                    <CustomerInfo
                        key={key}
                        data={customer}
                        onClick={() => openCustomerPanel({ data: customer })}
                    />
                );
            })}
        </CustomersContainer>
    );
};

export default CustomersList;
