import { UniversalFormProps } from '@rainbow-modules/forms/lib/components/UniversalForm';
import Button from 'components/Button';
import { MessageContext, MessageFormMode } from '../types';
import { Container } from '../styled';
import DrawerHeader from '../drawerHeader';
import Fields from './fields';
import { StyledUniversalForm, FormActions } from './styled';

const titles: Record<MessageContext, Record<MessageFormMode, string>> = {
    messages: {
        create: 'Create New Frequent Message',
        edit: 'Edit Frequent Message',
    },
    notes: {
        create: 'Create New Frequent Note',
        edit: 'Edit Frequent Note',
    },
};

const subtitles: Record<MessageContext, Record<MessageFormMode, string>> = {
    messages: {
        create: 'Easily save a message you send often. Once saved, you can quickly reuse it in future chats.',
        edit: 'Easily edit a message you send often. Once saved, you can quickly reuse it in future chats.',
    },
    notes: {
        create: 'Save notes that you often need to write. Once saved, you can quickly add them to future tasks or records, helping you stay consistent and save time.',
        edit: 'Save notes that you often need to write. Once saved, you can quickly add them to future tasks or records, helping you stay consistent and save time.',
    },
};

interface CreateEditMessageFormProps extends UniversalFormProps {
    mode: MessageFormMode;
    context: MessageContext;
    onRequestClose?: () => void;
}

const CreateEditMessageForm = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    context,
    mode = 'create',
    onRequestClose = () => {},
    onSubmit = () => {},
    ...formProps
}: CreateEditMessageFormProps) => {
    const updateButtonLabel = (
        mode === 'create'
            ? 'Create'
            : 'Update'
    );

    return (
        <StyledUniversalForm {...formProps} onSubmit={onSubmit}>
            <Container>
                <DrawerHeader
                    title={titles[context][mode]}
                    subtitle={subtitles[context][mode]}
                    onRequestClose={onRequestClose}
                />
                <Fields context={context} />
                <FormActions>
                    <Button
                        label="Cancel"
                        borderRadius="semi-rounded"
                        variant="outline-brand"
                        size="small"
                        onClick={onRequestClose}
                    />
                    <Button
                        label={updateButtonLabel}
                        borderRadius="semi-rounded"
                        variant="brand"
                        size="small"
                        type="submit"
                    />
                </FormActions>
            </Container>
        </StyledUniversalForm>
    );
};

export default CreateEditMessageForm;
