import styled from 'styled-components';
import Button from 'components/Button';

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-align: left;
    margin-top: 1.5rem;
    width: 100%;
`;

export const Container = styled.div`
    display: flex;
    margin: 0 -1.5rem;
    flex-direction: column;
    gap: 1.625rem;
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 1.5rem;
`;

export const StyledButton = styled(Button)`
    flex-shrink: 0;
`;
