import {
    createContext, ReactNode, useEffect, useRef,
} from 'react';
import { Client } from '@twilio/conversations';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { useParams } from 'react-router-dom';
import { isEmpty } from '@rainbow-modules/validation';

export interface ContextValue {
    client?: Client;
    isSMSEnabled: boolean;
}

export const Context = createContext<ContextValue>({
    client: undefined,
    isSMSEnabled: false,
});

interface Props {
    children: ReactNode;
}

const TwilioConversationProvider = ({ children }: Props) => {
    const { agentId = '' } = useParams<{ agentId?: string }>();
    const token = useRef<string>();
    const client = useRef<Client>();
    const { mutateAsync: fetchToken } = useHttpMutation<unknown, { token: string }>({
        pathname: `/agents/${agentId}/conversations/token`,
        method: 'GET',
    });

    useEffect(() => {
        if (isEmpty(agentId)) return () => {};
        (async () => {
            const createClient = async () => {
                const { token: accessToken } = await fetchToken({
                    pathname: `/agents/${agentId}/conversations/token`,
                });
                token.current = accessToken;
                return new Client(accessToken);
            };
            const updateClient = async () => {
                const { token: accessToken } = await fetchToken({
                    pathname: `/agents/${agentId}/conversations/token`,
                });
                token.current = accessToken;
                return client.current?.updateToken(accessToken);
            };

            try {
                client.current = await createClient();

                client.current?.on('tokenAboutToExpire', async () => {
                    client.current = await updateClient();
                });

                client.current?.on('tokenExpired', async () => {
                    client.current = await createClient();
                });
            } catch (error) {
                client.current = undefined;
                console.log(error);
            }
        })();
        return () => {
            client.current?.shutdown();
        };
    }, [agentId, fetchToken]);

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const contextValue = {
        client: client.current,
        isSMSEnabled: Boolean(client.current),
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export default TwilioConversationProvider;
