import styled from 'styled-components';
import ButtonIcon from 'components/ButtonIcon';

export const ActionButton = styled(ButtonIcon)`
    width: 3rem;
    height: 3rem;
    svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
        font-size: 1.5rem !important;
    }
`;

export const StyledFileInput = styled.input`
    display: none;
`;
