import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import CreateEditOpportunityForm from 'components/CreateEditOpportunityForm';
import CustomerActivity from 'components/CustomerActivity';
import SendNoteForm from 'components/SendNoteForm';
import useSendNote from 'data/hooks/useSendNote';
import OpportunityDetailsDrawer from 'components/OpportunityDetailsDrawer';
import useCustomerActivity from 'data/hooks/useCustomerActivity';
import { CardContent } from '../styled';

const CustomerDetailsActivity = () => {
    const { agentId = '', customerId = '' } = useParams();
    const messagesListRef = useRef<HTMLDivElement>(null);
    const [isSendingNote, setSendingNote] = useState(false);

    const { mutateAsync: sendNote } = useSendNote();

    const [messages, isLoadingMessages] = useCustomerActivity(
        agentId,
        customerId,
        {
            disabled: !agentId || !customerId,
        },
    );

    useEffect(
        () => messagesListRef.current?.scrollBy(0, messagesListRef.current?.scrollHeight),
        [messages.length],
    );

    const handleSendNote = async (note: string, files: File[]) => {
        setSendingNote(true);
        try {
            await sendNote({
                agentId,
                customerId,
                note,
                files,
            });
        } catch (error) {
            // TODO: handle error
            console.error(error);
        }
        setSendingNote(false);
    };

    return (
        <>
            <CardContent ref={messagesListRef}>
                <CustomerActivity
                    context="customer"
                    isLoading={isLoadingMessages}
                    messages={messages}
                    agentId={agentId}
                />
            </CardContent>
            <SendNoteForm onSend={handleSendNote} disableSend={isSendingNote} />
            <OpportunityDetailsDrawer agentId={agentId} />
            <CreateEditOpportunityForm />
        </>
    );
};

export default CustomerDetailsActivity;
