import {
    ReactNode, useCallback, useMemo, useState,
} from 'react';
import { DatePickerModal, Option } from 'react-rainbow-components';
import { getFormatter } from 'data/services/date/formatter';
import { StyledPicklist } from './styled';
import getYearsList from '../../helpers/insights/getYearsList';
import { Type } from './types';

interface PicklistValue {
    label?: string;
    name?: string | number;
    icon?: ReactNode;
    value?: any;
}

interface DateFilterProps {
    value?: string | Date[];
    onChange?: (value: string | Date[]) => void;
    type?: Type;
    [key: string]: unknown;
}

const dateFormatter = getFormatter('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
});

const optionsMap: Record<Type, Record<string, unknown>[]> = {
    day: [
        {
            name: 'current-month',
            label: 'Current Month',
        },
        {
            name: 'last-month',
            label: 'Last Month',
        },
        {
            name: 'custom',
            label: 'Custom',
        },
    ],
    month: [
        {
            name: 'current-year',
            label: 'Current Year',
        },
        ...getYearsList(2023),
    ],
};

const headerMap: Record<Type, string> = {
    day: 'Select Daily View',
    month: 'Select Monthly View',
};

const DateFilter = ({
    type = 'month',
    value: valueInProps,
    onChange = () => {},
    ...rest
}:DateFilterProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const openModal = useCallback(() => setIsOpen(true), []);
    const closeModal = useCallback(() => setIsOpen(false), []);

    const options = optionsMap[type];

    const pickListvalue = useMemo(() => {
        if (!valueInProps) {
            return options.at(0);
        }

        if (Array.isArray(valueInProps)) {
            const [from, to] = valueInProps;

            const label = (
                to
                    ? `${dateFormatter.format(from)} - ${dateFormatter.format(to)}`
                    : 'Custom date'
            );
            return {
                name: 'custom',
                label,
            };
        }
        const found = (
            valueInProps
                ? options.find((option) => option.name === valueInProps)
                : null
        ) || options[0];
        return { ...found };
    }, [options, valueInProps]);

    const pickListOptions = useMemo(
        () => options.map((option) => <Option key={`oppotunitues-convertion-filter-option__${option.name}`} {...option} />),
        [options],
    );

    const handlePicklistChange = useCallback(
        (newValue: PicklistValue) => {
            const { name } = newValue;
            if (name === 'custom') {
                return openModal();
            }
            return onChange(name as string);
        },
        [onChange, openModal],
    );

    const handleDatePickerChange = useCallback(
        (newValue: Date | Date[]) => {
            const newVal = Array.isArray(newValue) ? newValue : [newValue];
            if (newVal.length > 1) {
                closeModal();
            }
            onChange(newVal);
        },
        [closeModal, onChange],
    );

    return (
        <>
            <StyledPicklist
                onChange={handlePicklistChange}
                value={pickListvalue}
                {...rest}
            >
                {pickListOptions}
            </StyledPicklist>
            <DatePickerModal
                isOpen={isOpen}
                onRequestClose={closeModal}
                onChange={handleDatePickerChange}
                value={valueInProps}
                maxDate={new Date()}
                selectionType="range"
                title={headerMap[type]}
            />
        </>
    );
};

export default DateFilter;
