import { useEffect, useRef, useState } from 'react';
import { Outlet, useParams, useMatch } from 'react-router-dom';
import { useOpenModal } from '@rainbow-modules/hooks';
import CustomerProfile from 'components/CustomerProfile';
import BrainIcon from 'components/icons/brain';
import ListIcon from 'components/icons/list';
import FilesIcon from 'components/icons/files';
import useCustomer from 'data/firestore/agent/customer/use';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import useUserRoles from 'hooks/useUserRoles';
import {
    Columns,
    Container,
    LeftColumn,
    RightColumn,
    StyledCard,
    StyledTabset,
    StyledTab,
    TabLabel,
    StyledButton,
} from './styled';
import { ADD_EDIT_CUSTOMER_MODAL } from '../../../constants';

const CustomerDetails = () => {
    const { agentId = '', customerId = '' } = useParams();
    const { isAdmin } = useUserRoles();
    const navigate = useNavigateWithQuery();
    const match = useMatch(':agentId/customers/:customerId/:tabName');
    const leftColumnRef = useRef<HTMLDivElement>(null);
    const [maxHeight, setMaxHeight] = useState<number>();

    const { data: profileData } = useCustomer(
        agentId,
        customerId,
        {
            disabled: !agentId || !customerId,
        },
    );
    const [editCustomer] = useOpenModal(ADD_EDIT_CUSTOMER_MODAL);

    useEffect(() => {
        setMaxHeight(leftColumnRef.current?.clientHeight);
    }, []);

    return (
        <Container>
            {/* <StyledBreadcrumbs>
                <StyledBreadcrumb
                    label="Customers"
                    onClick={() => navigate(`/${agentId}/customers`)}
                />
                <StyledBreadcrumb label="Customer Details" />
            </StyledBreadcrumbs> */}
            <Columns ref={leftColumnRef}>
                <LeftColumn>
                    <StyledCard>
                        <CustomerProfile
                            agentId={agentId}
                            customerId={customerId}
                            showAdditionalInfo
                            showMetadata
                            footer={(
                                <StyledButton
                                    label="Edit Customer"
                                    variant="brand"
                                    shaded
                                    borderRadius="semi-rounded"
                                    onClick={() => editCustomer({
                                        mode: 'edit',
                                        header: 'Edit customer',
                                        customerData: profileData,
                                    })}
                                />
                            )}
                        />
                    </StyledCard>
                </LeftColumn>
                <RightColumn maxHeight={maxHeight}>
                    <StyledTabset
                        variant="line"
                        activeTabName={match?.params?.tabName}
                        onSelect={(event, eventName) => {
                            navigate(eventName);
                            event.preventDefault();
                        }}
                    >
                        <StyledTab
                            name="activity"
                            label={(
                                <TabLabel>
                                    <ListIcon />
                                    Activity
                                </TabLabel>
                            )}
                        />
                        <StyledTab
                            name="files"
                            label={(
                                <TabLabel>
                                    <FilesIcon />
                                    Files
                                </TabLabel>
                            )}
                        />
                        {isAdmin && (
                            <StyledTab
                                name="memory"
                                label={(
                                    <TabLabel>
                                        <BrainIcon />
                                        Memory
                                    </TabLabel>
                                )}
                            />
                        )}
                    </StyledTabset>
                    <Outlet />
                </RightColumn>
            </Columns>
        </Container>
    );
};

export default CustomerDetails;
