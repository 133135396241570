import { useEffect, useMemo, useState } from 'react';
import { RenderIf } from 'react-rainbow-components';
import Close from 'components/icons/close';
import Note from 'components/icons/note';
import fileSizeFormatter from 'data/services/size/formatter';
import trucateFilename from 'data/services/string/trucateFilename';
import {
    FileContainer, Label, SizeLabel, StyledZoomableImage, DeleteButton, ImageContainer,
    FileTypeIconContainer,
} from './styled';
import getFileBlob from './helpers/getFileBlob';

interface AttachedFileProps {
    file: File,
    onRemoveClick?: () => void;
}

const AttachedFile = ({
    file,
    onRemoveClick = () => {},
}: AttachedFileProps) => {
    const [url, setUrl] = useState<string>('');
    const [deleteButtonVisible, setDeleteButtonVisible] = useState<boolean>(false);

    const formattedFilename = useMemo(
        () => trucateFilename(file.name),
        [file],
    );

    const formattedFileSize = useMemo(
        () => fileSizeFormatter(file.size),
        [file.size],
    );

    const isImage = useMemo(() => /image/gi.test(file.type), [file]);

    useEffect(
        () => {
            (async () => {
                const blob = await getFileBlob(file);
                setUrl(URL.createObjectURL(blob));
            })();
        },
        [file],
    );

    return (
        <FileContainer
            onMouseEnter={() => setDeleteButtonVisible(true)}
            onMouseLeave={() => setDeleteButtonVisible(false)}
        >
            <ImageContainer>
                <RenderIf isTrue={isImage}>
                    <StyledZoomableImage
                        src={url || ''}
                        alt={file.name}
                        width={80}
                        height={80}
                    />
                </RenderIf>
                <RenderIf isTrue={!isImage}>
                    <FileTypeIconContainer>
                        <Note />
                    </FileTypeIconContainer>
                </RenderIf>
                <RenderIf isTrue={deleteButtonVisible}>
                    <DeleteButton
                        variant="destructive"
                        size="xx-small"
                        icon={<Close />}
                        onClick={onRemoveClick}
                    />
                </RenderIf>
            </ImageContainer>
            <Label>{formattedFilename}</Label>
            <SizeLabel>{formattedFileSize}</SizeLabel>
        </FileContainer>
    );
};

export default AttachedFile;
