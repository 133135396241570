import { useMemo } from 'react';
import ZoomInIcon from 'components/icons/zoomIn';
import Note from 'components/icons/note';
import ListTile from 'components/ListTile';
import { formatBytes } from 'data/services/files/formatter';
import {
    CellWrapper, FilePreviewContainer, FileTypeIconContainer,
    ZoomIconContainer, StyledZoomableImage,
} from './styled';
import checkIsImage from '../../helpers/checkIsImage';

interface FilePreviewColumnProps {
    value?: string;
    row?: Record<string, unknown>;
}

interface FileIconProps {
    fileName?: string;
    contentType?: string;
    url?: string;
}

const FilePreview = ({
    fileName = '',
    contentType,
    url,
}: FileIconProps) => {
    const isImage = useMemo(
        () => (contentType && checkIsImage(contentType)),
        [contentType],
    );

    if (!url) return null;

    if (isImage) {
        return (
            <FilePreviewContainer>
                <StyledZoomableImage
                    alt={fileName}
                    src={url}
                    width={38}
                    height={38}
                />
                <ZoomIconContainer>
                    <ZoomInIcon />
                </ZoomIconContainer>
            </FilePreviewContainer>
        );
    }
    return (
        <FilePreviewContainer disableInteraction>
            <FileTypeIconContainer>
                <Note />
            </FileTypeIconContainer>
        </FilePreviewContainer>
    );
};

const FilePreviewColumn = ({ value = '', row }: FilePreviewColumnProps) => (
    <CellWrapper>
        <ListTile
            icon={(
                <FilePreview
                    fileName={row?.name as string}
                    url={value}
                    contentType={row?.contentType as string}
                />
            )}
            title={row?.name as string}
            subtitle={formatBytes(row?.size as number)}
        />
    </CellWrapper>
);

export default FilePreviewColumn;
