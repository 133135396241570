import styled from 'styled-components';
import Picklist from 'components/Picklist';

export const StyledPicklist = styled(Picklist)`
    width: 11.75rem;

    input {
        border-radius: 0.75rem;
    }
`;
