import styled from 'styled-components';
import { GREY_50 } from '../../../../constants';

export const TooltipContainer = styled.div`
    display: flex;
    width: 10.5rem;
    padding: 0.75rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    border-radius: 0.375rem;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.65) 100%), #8692D0;
    color: ${GREY_50};
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; 

    & > h2 {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.125rem;
    }

    & > div {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        > span {
            text-transform: capitalize;
        }

        > svg {
            width: 1rem;
            height: 1rem;
        }
    }
`;
