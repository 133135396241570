import styled from 'styled-components';
import { Tab } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import Card from 'components/Card';
import GoogleAddressLookup from 'components/GoogleAddressLookup';
import Input from 'components/Input';
import LanguagePicker from 'components/LanguagePicker';
import LoadingShape from 'components/LoadingShape';
import UniversalForm from 'components/UniversalForm';
import { GREY_500, GREY_800, DIVIDER } from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.625rem;
    align-self: stretch;
    flex-grow: 1;
    width: 100%;
`;

export const StyledCard = styled(Card)`
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    gap: 1.625rem;
`;

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 120px);
`;

export const StyledForm = styled(UniversalForm)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.625rem;
    align-self: stretch; 
    flex-wrap: wrap;
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 1.625rem;
    align-self: stretch; 

    @media (max-width: 1000px) {
        flex-direction: column; 
    }
`;

export const Title = styled.h3`
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0125rem;
    color: ${GREY_800};
`;

export const Description = styled.p`
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1rem;
    color: ${GREY_500};
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.50rem;
    width: 100%;
`;

export const StyledLoadingShape = styled(LoadingShape)`
    margin-bottom: 5px;
`;

export const FormFooter = styled.footer`
    display: flex;
    align-items: flex-end;
    gap: 1rem;
    align-self: stretch;
`;

export const StyledInput = styled(Input)`
    flex: 1;

    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    input {
        border: 1px solid ${DIVIDER};

        ::placeholder {
            font-weight: 300 !important;
        }
    }
    
    div[id^="error-message"] {
        align-self: flex-start;
    }
`;

export const StyledGoogleAddressLookup = styled(GoogleAddressLookup)`
    flex: 1;
    
    div[id^="error-message"] {
        align-self: flex-start;
    }
`;

export const StyledLanguagePicker = styled(LanguagePicker)`
    flex: 1;
    max-width: calc(50% - .8125rem);
    
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: inline-flex;
    }

    @media (max-width: 1000px) {
        max-width: unset;
    }
`;

export const InputLabel = styled.span`
    display: flex;
    margin: 0 2px 2px;
    gap: 0.25rem;
`;

export const TooltipButton = styled(ButtonIcon)`
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    border: none;
    padding: 0;
    margin: 0;

    svg {
        width: 1rem !important;
        hegiht: 1rem !important;
        font-size: 1rem !important;
    }
`;

export const TooltipContainer = styled.div`
    width: 280px;
    display: flex;
    padding: 0.25rem 0.75rem;
    align-items: flex-start;
    gap: 0.625rem;
    flex: 1 0 0;
    align-self: stretch;
    text-align: left;
`;

export const StyledTab = styled(Tab)`
    display: flex;
    width: 9.75rem;
    height: 3.4rem;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    margin-top: -1rem;
    color: ${GREY_800};

    button::before {
        border-radius: 0;
        height: 2px;
    }
`;

export const TabLabel = styled.span`
    display: flex;
    padding: 0.625rem 1.25rem;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
    letter-spacing: 0.02688rem;
`;
