import styled from 'styled-components';
import { ButtonGroupPicker } from 'react-rainbow-components';
import {
    GREY_100, GREY_200, GREY_300, GREY_50, GREY_500, GREY_700, WHITE,
} from '../../../constants';

export const FiltersContainer = styled.div`
    display: flex;
    gap: 1rem;
    width: content;
`;

export const StyledButtonGroupPicker = styled(ButtonGroupPicker)`
    background: ${WHITE};
    border-radius: 0.75rem;
    height: 2.75rem;

    label:first-child > span {
        border-radius: 0.75rem 0px 0px 0.75rem;
    }

    label:last-child > span {
        border-radius: 0 0.75rem 0.75rem 0;
    }

    label {
        z-index: unset;
        border-radius: 0.5rem;

        span {
            color: ${GREY_300};
            font-size: 0.9375rem;
            background: transparent;
            box-shadow: none;
            border-color: ${GREY_100};
            z-index: unset;
            display: flex;
            padding: 0.625rem;
            justify-content: center;
            align-items: center;

            &:hover {
                color: ${GREY_500};
            }
        }
        
        input:checked + span {
            background: ${GREY_50};
            border-color: ${GREY_100};
            color: ${GREY_700};
            box-shadow: none;
        }

        input[disabled] + span {
            background: ${GREY_50};
            border-color: ${GREY_100};
            color: ${GREY_200};
            box-shadow: none;
        }
    }
`;

export const TotalLabel = styled.div`
    display: flex;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; 
`;

export const ReportContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 1.6rem;
`;

export const ChartContainer = styled.div`
    display: flex;
    flex: 1;
`;
