import styled from 'styled-components';
import { LoadingShape as RainbowLoadingShape } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import ZoomableImage from 'components/ZoomableImage';
import {
    GREY_300, GREY_400, ORANGE_400, WHITE,
} from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const Text = styled.span``;

export const FilesListContainer = styled.div`
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 0.5rem 0;
    max-height: 200px;
    overflow-y: auto;
    padding: 0.75rem 0.75rem 0.75rem 0;
`;

export const FileContainer = styled.div`
    position: relative;
    border-radius: 0.375rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ImageContainer = styled.div`
    min-width: 40px;
    position: relative;
    display: flex;
    justify-content: center;
`;

export const FileTypeIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 12px;
    background: ${ORANGE_400};
    color: ${WHITE};

    & > svg {
        width: 2rem !important;
        height: 2rem !important;
    }
`;

export const Label = styled.span`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
    margin-top: 0.25rem;
    color: ${GREY_400}
`;

export const SizeLabel = styled.span`
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
    color: ${GREY_300};
`;

export const StyledZoomableImage = styled(ZoomableImage)`
    border-radius: 0.375rem;
    align-self: center;
    max-width: 80px;
    max-height: 80px;
    width: auto;
    height: auto;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: -0.5rem;
    right: -0.5rem;
    border: transparent;

    :hover {
        border: transparent;
    }
    
    svg {
        width: 1rem !important;
        height: 1rem !important;    
    }
`;

export const StyledLoadingShape = styled(RainbowLoadingShape)`
    display: inline-flex;
    max-width: 60px;
    min-height: 80px;
    width: 60px;
    height: 80px;
    overflow: hidden;
    border-radius: 8px;

    & > div {
        border-radius: 8px;
        max-width: 60px !important;
        height: 80px;
    }
`;
