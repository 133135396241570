import { Field } from 'react-final-form';
import PipelineStageTypePicker from 'components/PipelineStageTypePicker';
import { validateNotEmpty } from 'data/validators';
import {
    FieldsContainer,
    StyledInput,
    Message,
    Row,
} from './styled';

interface Params {
    message?: string;
    disabledFields?: string[],
}

const Fields = ({
    disabledFields = [],
    message = '',
}: Params) => (
    <>
        <Message>{message}</Message>
        <FieldsContainer>
            <Row>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={StyledInput}
                    name="name"
                    label="Stage Name"
                    labelAlignment="left"
                    placeholder="Enter a name for the stage"
                    borderRadius="semi-square"
                    autoComplete="off"
                    required
                    validate={validateNotEmpty('This field is required')}
                />
            </Row>
            <Row>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={PipelineStageTypePicker}
                    name="type"
                    label="Stage Type"
                    labelAlignment="left"
                    borderRadius="semi-square"
                    disabled={disabledFields.includes('type')}
                    required
                />
            </Row>
        </FieldsContainer>
    </>
);

export default Fields;
