import { useCallback, useMemo } from 'react';
import useMultipartMutation from './useMultipartMutation';

const buildFormBata = (values: Record<string, unknown>) => {
    const data = new FormData();
    Object.keys(values).forEach(
        (key) => {
            const value = values[key];
            if (Array.isArray(value) && value.length > 0 && value[0] instanceof File) {
                value.forEach((file) => data.append(key, file));
            } else {
                data.append(key, value as any);
            }
        },
    );
    return data;
};

const useSendNote = () => {
    const {
        mutate: hookMutate,
        mutateAsync: hookMutateAsync,
        ...rest
    } = useMultipartMutation({
        method: 'POST',
    });
    const mutate = useCallback(
        ({
            agentId,
            customerId,
            inboxId,
            opportunityId,
            note,
            files,
        }: Record<string, unknown>) => hookMutate({
            pathname: '/send-note',
            body: buildFormBata({
                agentId,
                customerId,
                inboxId,
                opportunityId,
                text: note,
                attachedFiles: files,
            }),
        }),
        [hookMutate],
    );

    const mutateAsync = useCallback(
        ({
            agentId,
            customerId,
            inboxId,
            opportunityId,
            note,
            files,
        }: Record<string, unknown>) => hookMutateAsync({
            pathname: '/send-note',
            body: buildFormBata({
                agentId,
                customerId,
                inboxId,
                opportunityId,
                text: note,
                attachedFiles: files,
            }),
        }),
        [hookMutateAsync],
    );

    return useMemo(() => ({ mutate, mutateAsync, ...rest }), [mutate, mutateAsync, rest]);
};

export default useSendNote;
