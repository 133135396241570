import styled from 'styled-components';
import { ActivityTimeline, TimelineMarker } from 'react-rainbow-components';
import { Virtuoso } from 'react-virtuoso';

export const CardContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
`;

export const Container = styled.div`
    box-sizing: border-box; 
    padding: 2.25rem 3rem;
`;

export const TimeLine = styled(ActivityTimeline)`
    height: 100%;
`;

export const StyledTimelineMarker = styled(TimelineMarker)`
    max-width: calc(100% - 6rem);
`;

export const StyledVirtuoso = styled(Virtuoso)`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    padding: 0 3rem;
    margin: 2.25rem 0;
`;

export const IconContainer = styled.div<{ background: string, color: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    background: ${(props) => props.background};
    color: ${(props) => props.color};
    border-radius: 100%;

    svg {
        width: 1.5rem;
        height: 1.5rem;
    }
`;

export const ItemBody = styled.div`
    display: flex;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.25rem;
    align-self: stretch;
    border-radius: 0.75rem;
    border: 1px solid #DBDADE;
    background: #FFF;
`;

export const BodyTitle = styled.h3`
    color: #1E1E1E;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;

`;

export const BodyContent = styled.p`
    color: #000;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
`;

export const LabelContainer = styled.div``;

export const LabelDate = styled.div`
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    color: #88888D;
`;

export const LabelMessage = styled.div`
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;

    b {
        font-weight: 700;
    }
`;
