const builtinModels = [
    'gpt-3.5-turbo-0125',
    'gpt-4-turbo',
    'gpt-4-turbo-2024-04-09',
    'gpt-4o-2024-05-13',
    'gpt-4o-2024-08-06',
    'gpt-4o-mini-2024-07-18',
];

const isBuiltinModel = (modelId: string = '') => builtinModels.includes(modelId);

export default isBuiltinModel;
