import { useCallback, useEffect, useState } from 'react';
import { RenderIf } from 'react-rainbow-components';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import { showAppNotification } from '@rainbow-modules/app';
import { isEmpty } from '@rainbow-modules/validation';
import ActionIcon from 'components/icons/pencil4';
import Pencil from 'components/icons/pencil3';
import ExpandIcon from 'components/icons/expand';
import ShrinkIcon from 'components/icons/shrink';
import Languages from 'components/icons/languages';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { HELP_ME_WRITE_MODAL } from '../../constants';
import {
    ActionButton,
    StyledModal,
    ModalSubtitle,
    ModalBody,
    EnhanceActionsContainer,
    StyledButton,
    HeaderContainer,
    ModalTitle,
} from './styled';
import TextItem from './TextItem';
import InstructionInput from './InstructionInput';
import TextTransformationsList from './TextTransformationsList';
import { TextTransformation, TransformHistoryItem } from './types';

interface HelpMeWriteProps {
    agentId?: string;
    value?: string;
    onInsert?: (value: string) => void;
    disabled?: boolean;
}

const HelpMeWrite = ({
    agentId = '',
    value = '',
    onInsert = () => {},
}: HelpMeWriteProps) => {
    const [currentValue, setCurrentValue] = useState<string>('');
    const [transformsHistory, setTransformHistory] = useState<TransformHistoryItem[]>([]);
    const { isOpen, ...connectedFormModalProps } = useConnectModal(HELP_ME_WRITE_MODAL);
    const [openModal, closeModal] = useOpenModal(HELP_ME_WRITE_MODAL);

    useEffect(() => {
        if (isOpen) {
            setCurrentValue(value);
        }
        return () => {
            if (isOpen) {
                setTransformHistory([]);
                setCurrentValue('');
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const {
        mutateAsync: transformText,
        isLoading,
    } = useHttpMutation<{ prompt: string, value: string }, { value: string }>({
        method: 'POST',
        pathname: `/agents/${agentId}/helpmewrite`,
    });

    const createTextTransform = useCallback(
        (transformation: TextTransformation) => async () => {
            try {
                const { value: transformedText } = await transformText({
                    body: {
                        prompt: transformation.prompt,
                        value: currentValue,
                    },
                });
                setTransformHistory(
                    (prev) => [
                        ...prev,
                        {
                            transformation,
                            text: transformedText,
                        },
                    ],
                );
                setCurrentValue(transformedText);
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
        },
        [transformText, currentValue],
    );

    const handleInsert = (message: string) => {
        onInsert(message);
        closeModal();
    };

    const handleRollback = useCallback(
        () => {
            const newHistory = [...transformsHistory];
            newHistory.splice(-1);
            setTransformHistory(newHistory);
            setCurrentValue(newHistory.at(-1)?.text || value);
        },
        [transformsHistory, value],
    );

    const handleRegenerate = useCallback(
        () => {
            const latestTransform = transformsHistory.at(-1)?.transformation;
            if (latestTransform) {
                createTextTransform(latestTransform)();
            }
        },
        [createTextTransform, transformsHistory],
    );

    return (
        <>
            <ActionButton
                tooltip="Help me write"
                icon={<ActionIcon />}
                onClick={() => openModal({})}
            />
            <StyledModal
                isOpen={isOpen}
                borderRadius="semi-square"
                size="medium"
                {...connectedFormModalProps}
                title={(
                    <HeaderContainer>
                        <ModalTitle>Help me Write</ModalTitle>
                        <ModalSubtitle>
                            Need help drafting your message? Use AI to
                            rephrase or adjust the tone of your text.
                            Choose from options like concise, friendly,
                            or professional to fit your needs.
                        </ModalSubtitle>
                    </HeaderContainer>
                )}
            >
                <ModalBody>
                    <TextItem title="Original Text" text={value} hideActions />
                    <RenderIf isTrue={!isEmpty(transformsHistory)}>
                        <TextTransformationsList
                            isLoading={isLoading}
                            title={transformsHistory.at(-1)?.transformation?.label}
                            text={transformsHistory.at(-1)?.text}
                            disableUndo={transformsHistory.length < 2}
                            onInsert={handleInsert}
                            onUndo={handleRollback}
                            onRegenerate={handleRegenerate}
                        />
                    </RenderIf>
                    <EnhanceActionsContainer>
                        <StyledButton
                            borderRadius="semi-rounded"
                            size="small"
                            variant="border"
                            onClick={createTextTransform({
                                label: 'Translate',
                                prompt: 'Translate the original text. If the text is in Spanish, translate to English. If the text is in English, translate to Spanish. Ensure that the original meaning, tone, and context remain intact.',
                            })}
                            disabled={isLoading}
                        >
                            <Languages />
                            Translate
                        </StyledButton>
                        <StyledButton
                            borderRadius="semi-rounded"
                            size="small"
                            variant="border"
                            onClick={createTextTransform({
                                label: 'Fix-Grammar',
                                prompt: 'Fix grammar, spelling, and awkward phrasing in the text while keeping the original message.',
                            })}
                            disabled={isLoading}
                        >
                            <Pencil />
                            Fix-Grammar
                        </StyledButton>
                        <StyledButton
                            borderRadius="semi-rounded"
                            size="small"
                            variant="border"
                            onClick={createTextTransform({
                                label: 'Simplify',
                                prompt: 'Reduce the text (by at least 30%) and make it easier to understand using simpler words and shorter sentences. Ensure that the original meaning, tone, and context remain intact.',
                            })}
                            disabled={isLoading}
                        >
                            <ShrinkIcon />
                            Simplify
                        </StyledButton>
                        <StyledButton
                            borderRadius="semi-rounded"
                            size="small"
                            variant="border"
                            onClick={createTextTransform({
                                label: 'Expand',
                                prompt: 'Expand the text (to no more than 320 characters) by adding more detail and explanation. Ensure that the original meaning, tone, and context remain intact.',
                            })}
                            disabled={isLoading}
                        >
                            <ExpandIcon />
                            Expand
                        </StyledButton>
                    </EnhanceActionsContainer>
                    <InstructionInput
                        disabled={isLoading}
                        onSubmit={(instruction: string) => createTextTransform({
                            label: 'Custom',
                            prompt: instruction,
                        })()}
                    />
                </ModalBody>
            </StyledModal>
        </>
    );
};

export default HelpMeWrite;
