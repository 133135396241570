import { ReactNode, useContext, useRef } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { InternalTooltip, useDefaultTooltipConnector } from 'react-rainbow-components';
import { BaseProps, BoardCard, BoardColumn } from '../types';
import context, { Context } from '../context';
import { ColumnContainer, ColumnHeader } from './styled';
import ColumnTitle from './columnTitle';

interface KanbanBoardCollapsedColumnProps extends BaseProps {
    title?: string;
    column: BoardColumn;
    cards?: BoardCard[];
    tooltip?: string;
    actions?: ReactNode;
    disabled?: boolean
    index: number
}

const KanbanBoardCollapsedColumn = ({
    index,
    title,
    column,
    cards = [],
    columnHeaderComponent: HeaderComponent = ColumnTitle,
    tooltip,
    actions,
    onColumnExpand = () => {},
    disabled = false,
}: KanbanBoardCollapsedColumnProps) => {
    const { columnEditMode } = useContext<Context>(context);
    const containerRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef();

    const {
        onMouseEnter,
        onMouseLeave,
        isVisible,
    } = useDefaultTooltipConnector({
        tooltipRef,
        triggerRef: () => containerRef as any,
    });

    if (columnEditMode) {
        return (
            <Draggable
                draggableId={column.name}
                index={index}
                isDragDisabled={!column.draggable}
            >
                {(provided) => (
                    <ColumnContainer
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        disabled={disabled}
                        collapsed
                    >
                        <ColumnHeader collapsed>
                            <HeaderComponent
                                collapsed
                                title={title}
                                column={column}
                                cards={cards}
                            />
                        </ColumnHeader>
                        {actions}
                    </ColumnContainer>
                )}
            </Draggable>
        );
    }

    return (
        <>
            <ColumnContainer
                ref={containerRef}
                onClick={() => onColumnExpand(column)}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                collapsed
                disabled={disabled}
            >
                <ColumnHeader collapsed>
                    <HeaderComponent
                        collapsed
                        title={title}
                        column={column}
                        cards={cards}
                    />
                </ColumnHeader>
                {actions}
            </ColumnContainer>
            <InternalTooltip
                triggerElementRef={() => containerRef}
                ref={tooltipRef}
                preferredPosition="top"
                isVisible={isVisible && !!tooltip}
            >
                <div>{tooltip}</div>
            </InternalTooltip>
        </>
    );
};

export default KanbanBoardCollapsedColumn;
