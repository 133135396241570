import { useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Option } from 'react-rainbow-components';
import { useOpenModal } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import Picklist from 'components/Picklist';
import useManageResponderMapping from 'data/hooks/useManageResponderMapping';
import { ADD_EDIT_LLM_RESPONDER_MODAL } from '../../../constants';
import { CellWrapper } from './styled';
import context, { Context } from '../context';

interface Props {
    row?: Record<string, unknown>;
    agentId: string;
    disabled?: boolean;
}

const AIPersonaColumn = ({
    row,
    agentId,
    disabled = false,
}: Props) => {
    const { responders, responderMapping } = useContext<Context>(context);
    const navigate = useNavigate();

    const [openModal] = useOpenModal(ADD_EDIT_LLM_RESPONDER_MODAL);

    const {
        create: createMapping,
        update: updateMapping,
        delete: deleteMapping,
    } = useManageResponderMapping(agentId);

    const respondersList = useMemo(
        () => responders?.filter(
            (responder) => responder.featureSchema === row?.featureSchema,
        ).map(
            (responder) => (
                <Option
                    key={responder.id}
                    name={responder.id}
                    label={responder.name}
                    value={responder.id}
                />
            ),
        ),
        [responders, row?.featureSchema],
    );

    const value = useMemo(() => {
        const mapping = responderMapping?.find(
            (responderMap) => responderMap.feature === row?.name,
        );
        if (mapping) {
            const featureResponder = responders?.find(
                (responder) => responder.id === mapping.responderId,
            );
            if (featureResponder) {
                return {
                    name: featureResponder.id,
                    label: featureResponder.name,
                    value: featureResponder.id,
                };
            }
        }

        return undefined;
    }, [responderMapping, responders, row]);

    const updateResponder = useCallback(
        async (responderId: string) => {
            const mapping = responderMapping?.find(
                (responderMap) => responderMap.feature === row?.name,
            );
            if (!mapping) {
                await createMapping({
                    feature: row?.name as string,
                    featureSchema: row?.featureSchema as string,
                    responderId,
                });
                return;
            }
            await updateMapping({
                mappingId: mapping.id,
                responderId,
            });
        },
        [createMapping, responderMapping, row, updateMapping],
    );

    const handleResponderCreated = useCallback(
        async (responder: Record<string, unknown>) => {
            const { id } = responder;
            await updateResponder(id as string);
            navigate(`/${agentId}/llms/${id}`);
        },
        [updateResponder, navigate, agentId],
    );

    const onResponderChange = useCallback(
        async (newValue: PicklistValue) => {
            if (newValue.name === 'new') {
                openModal({
                    initialValues: {
                        skill: 'system',
                        feature: row?.featureSchema,
                    },
                    onSucess: handleResponderCreated,
                });
                return;
            }

            if (newValue.name === 'none') {
                const mapping = responderMapping?.find(
                    (responderMap) => responderMap.feature === row?.name,
                );
                if (mapping) {
                    await deleteMapping(mapping.id);
                }
                return;
            }

            await updateResponder(newValue.value as string);
        },
        [
            deleteMapping,
            handleResponderCreated,
            openModal,
            responderMapping,
            row?.featureSchema,
            row?.name,
            updateResponder,
        ],
    );

    return (
        <CellWrapper>
            <Picklist
                placeholder="Click to select AI Persona"
                disabled={disabled}
                value={value}
                onChange={onResponderChange}
            >
                <Option name="new" label="Create a Responder" />
                <Option name="none" label="None" />
                <Option variant="header" label="Your responders" />
                {respondersList}
            </Picklist>
        </CellWrapper>
    );
};

export default AIPersonaColumn;
