import styled, { css } from 'styled-components';
import {
    Accordion, AccordionSection, CheckboxGroup, ProgressCircular, Picklist, BadgeOverlay,
    ButtonGroupPicker,
} from 'react-rainbow-components';
import Card from 'components/Card';
import DatePickerFilter from 'components/DatePickerFilter';
import ChevronDown from 'components/icons/chevronDown';
import Input from 'components/Input';
import PipelineStageSelect from 'components/PipelineStageSelect';
import PriceFilter from 'components/PriceFilter';
import TeamMemberSelect from 'components/TeamMemberSelect';
import { color } from 'theme';
import {
    GREY_300,
    GREY_800,
    GREY_400,
    GREY_500,
    BACKGROUND,
    PURPLE_500,
    WHITE,
    PURPLE_100,
    PURPLE_200,
    GREY_200,
    GREY_100,
} from '../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0;
    box-sizing: border-box;
`;

export const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
`;

export const Content = styled.div`
    display: flex;
    gap: 1rem;
    height: 100%;
`;

export const StyledCard = styled(Card)`
    height: calc(100vh - 161px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const SearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 1.5rem;
`;

export const SearchInput = styled(Input)`
    flex: 1;
    min-width: 14rem;
    margin-top: 4px;
    
    input[disabled] {
        pointer-events: none;
        background: transparent;
        border: none;
        
        ::placeholder {
            color: ${GREY_200} !important;
        }
    }

    ${(props) => props.disabled && css`
        span {
            color: ${GREY_100} !important;
        }
    `};
`;

export const RightContent = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
`;

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 2rem 1rem 1rem 1.25rem;
    height: fit-content;
    z-index: 1;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%);
    margin-top: -2rem;
    z-index: 1;
`;

export const EntriesText = styled.span`
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1.25rem;
    color: ${GREY_300};
`;

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const UserCellWrapper = styled(CellWrapper)`
    padding: 10px 10px 10px 10px;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.15rem;
    margin: 0.5rem 0.5rem;
`;

export const StyledProgressCircular = styled(ProgressCircular)`
    min-width: 50px;
    width: 50px;
    height: 50px;

    svg {
        width: 50px;
        height: 50px;
        overflow: visible !important;
        
        circle {
            stroke-width: 4px;
        }
    }

    & > h1 {
        font-size: 1px;
        color: ${WHITE};
        &::after {
            color: ${GREY_400};
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.375rem;

            ${(props) => props.variant && css`
                content: '${props.value}';

            `}
        }
    }
`;

export const FiltersContainer = styled.div`
    position: relative; 
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-self: stretch;
    flex-shrink: 0;
    flex: 1 0 0;
    overflow: hidden;
    padding: 0;
`;

export const FiltersContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    overflow: auto;
    padding: 0 0.375rem 3rem;
`;

export const FilterHeader = styled.div`
    padding: 1rem 1rem 0 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Title = styled.h1`
    font-size: 1.125rem;
    font-weight: 500;
    color: ${GREY_800};
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
`;

export const AccordionContainer = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;

    & > div:first-child {
        background: none;
    }
    
    & > div:last-child {
        width: 100%;
        padding: 0 0.5rem 0.5rem 0.5rem;
    }
`;

interface StyledContentProps {
    isActive: boolean;
}
export const StyledContent = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<StyledContentProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    width: 100%;
    color: ${GREY_500};
    padding: 1rem;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
    user-select: none;
    gap: 0.5rem;
    position: relative;

    &:hover, &:focus, &:active {
        color: ${GREY_500};
        text-decoration: none;
        background-color: ${BACKGROUND};
    }

    ${(props) => props.isActive && css`
        background: ${BACKGROUND};

        &::before {
            content: '';
            position: absolute;
            border-radius: 100%;
            background: ${PURPLE_500};
            width: 0.375rem;
            height: 0.375rem;
            left: 0.375rem;
            top: 1.54294rem;
        }
    `}

`;

export const AccordionLabelContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

interface StyledExpandedProps {
    expanded: boolean;
}

export const AccordionLabel = styled.span`
    color: ${GREY_800};
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
`;

export const StyledChevron = styled(ChevronDown).withConfig({
    shouldForwardProp: (prop) => !['expanded'].includes(prop),
})<StyledExpandedProps>`
    width: 1.15rem;
    height: 1.15rem;
    transition: transform 0.25s ease-in-out 0s;

    ${(props) => props.expanded && css`
        transition: transform 0.25s ease-in-out 0s;
        transform: rotate(180deg);
    `}
`;

export const StyledCheckboxGroup = styled(CheckboxGroup)`
    padding-left: 2.5rem;

    > div {
        gap: 0.25rem;
    }

    label > span:last-child {
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        color: ${GREY_400};
    }
`;

export const StyledTeamMemberSelect = styled(TeamMemberSelect)`
    padding-left: 2.5rem;

    > div {
        gap: 0.25rem;
    }
`;

export const StyledPipelineStageSelect = styled(PipelineStageSelect)`
    padding-left: 2.5rem;
`;

export const StyledAccordion = styled(Accordion)`
    li {
        box-shadow: none;
    }
`;

export const StyledAccordionSection = styled(AccordionSection)`
    button {
        box-shadow: none;

        :focus {
            box-shadow: none;
        }
    }

    section {
        box-shadow: none;
    }

    svg {
        order: 3;
    }

    h3 {
        padding-left: 0;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
    }
`;

export const ActivityAccordionContent = styled.div`
    margin: -0.875rem -1rem -0.875rem -3rem;
    display: flex;
    flex: 1;
    max-height: 500px;
    flex-direction: column;
    overflow: auto;
`;

export const StyledBadgeOverlay = styled(BadgeOverlay)`
    ${(props) => props.variant === 'brand' && css`
        & > span {
            background: ${PURPLE_100};
            color: ${PURPLE_500};
        }
    `};
`;
export const StyledPicklist = styled(Picklist)<{ color?: string }>`
    width: fit-content;

    input,
    input:focus,
    input:active {
        border: 1px solid transparent;
        color: ${(props) => props.color};
        background: ${(props) => `${props.color}29`};
        transition: all 200ms ease-in;
    }

    input[disabled] {
        padding-left: 0.8rem;
        border: 1px solid transparent;
        color: ${(props) => `${props.color}60`};
        background: ${(props) => `${props.color}19`};
    }
    
    input:focus,
    input:active {
        box-shadow: ${(props) => `${props.color}29`} 0px 0px 2px;
    }

    span::after {
        color: ${(props) => props.color};
    }

    ${(props) => !props.value?.value.removable && css`
        input {
            text-transform: capitalize;
        }
    `};
`;

export const StyledButtonGroupPicker = styled(ButtonGroupPicker)`
    background: ${PURPLE_100};
    border-radius: 0.75rem;
    height: 3rem;

    label:first-child > span {
        border-radius: 0.75rem 0px 0px 0.75rem;
    }

    label:last-child > span {
        border-radius: 0 0.75rem 0.75rem 0;
    }

    label {
        z-index: unset;
        border: none;
        box-shadow: none;
        border-radius: 0.5rem;

        span {
            color: ${PURPLE_500};
            background: transparent;
            z-index: unset;
            box-shadow: none;
            border: none;
            display: flex;
            width: 3rem;
            height: 3rem;
            padding: 0.625rem;
            justify-content: center;
            align-items: center;

            & > svg {
                width: 1.5rem !important;
                height: 1.5rem !important;
            }
        }
        
        input:checked + span {
            background: ${PURPLE_200};
            box-shadow: none;
            border: none;
        }
    }
`;

export const FilterButtonDropDown = styled.div`
    width: 21.25rem;
    overflow: hidden;
    height: 100%;
    border-radius: 1rem;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    background: ${color('background.main')};
    box-shadow: 0px var(--Primitives-Number-scale-4, 4px) var(--Primitives-Number-scale-4, 4px) 0px rgba(0, 0, 0, 0.06), 0px var(--Primitives-Number-scale-8, 8px) var(--Primitives-Number-scale-24, 24px) 0px rgba(75, 70, 92, 0.16);
    transition: opacity 0.1s linear, visibility 0.1s linear;

    &:focus,
    &:active {
        outline: none;
    }             
`;

export const StyledDatePickerFilter = styled(DatePickerFilter)`
    width: unset;
    flex: 1;
    margin: 0 0.5rem 0 2.5rem;
`;

export const StyledPriceFilter = styled(PriceFilter)`
    padding: 0rem 0.5rem 0rem 2.5rem;
`;

export const CustomerNotificationBadge = styled(BadgeOverlay)`
    & > span:last-child {
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 100%;
    }
`;
